import React from 'react';
import PropTypes from 'prop-types';
import { ModalCustomizeTheme } from '../../../../../components/modal/index.jsx';
import { useForm } from 'react-hook-form';
import { RenderForm } from '../../../../../components/forms/index.jsx';
import { FORM_INPUT_POLICY, schemaPolicy } from '../../forms/index.js';
import { useFormState } from '../../hooks/index.js';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonTheme } from '../../../../../components/buttons/index.jsx';
import { Col, Row, Input } from 'antd';
const { TextArea } = Input;

export const PolicyModal = (props) => {
  const { open, onToggle, policyText, handleSubmitSuccess } = props;
  const { policyDefaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({
    policyDefaultValues,
    resolver: yupResolver(schemaPolicy),
  });

  function onSubmitSuccess() {
    const values = getValues();
    handleSubmitSuccess(values?.policyCheck?.[0]);
  }

  return (
    <ModalCustomizeTheme
      title="นโยบายและเงื่อนไขการให้บริการ"
      open={open}
      onToggle={onToggle}
      isClosable={false}
    >
      <Row style={{ marginTop: '5px' }}>
        <Col span={24}>
          <TextArea value={policyText} style={{ height: '50vh', backgroundColor: '#F5F5F5' }} />
        </Col>
      </Row>
      <Row style={{ marginTop: '5px' }}>
        <RenderForm
          control={control}
          forms={FORM_INPUT_POLICY()}
          setValue={setValue}
          getValues={getValues}
        />
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTheme useFor="CONFIRM" title="ยืนยัน" onClick={handleSubmit(onSubmitSuccess)} />
        </Col>
      </Row>
    </ModalCustomizeTheme>
  );
};

PolicyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  policyText: PropTypes.string,
  handleSubmitSuccess: PropTypes.func.isRequired,
};

PolicyModal.defaultProps = {
  open: false,
  policyText: '',
  onToggle: () => null,
  handleSubmitSuccess: () => null,
};
