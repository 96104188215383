import { Layout } from 'antd';
import styled from 'styled-components';
import { color } from '../../../resources/color';
const { Header, Sider, Content } = Layout;

export const ContentStyle = styled(Content)`
  margin: 0;
  position: fixed !important;
  top: 80px;
  width: 100vw;
  height: 87vh;
  padding: 5px 0 5px 0;
  border-radius: 0px 40px 0px 0px;
  orverflow-y: auto;
  ${(props) => ({ ...props.style })}
`;

export const ContentStyleFullScreen = styled(Content)`
  margin: 0;
  width: 100vw;
  height: 95vh;
  padding: 0;
  orverflow-y: auto;
  ${(props) => ({ ...props.style })}
`;

export const SideBarStyle = styled(Sider)`
  // overflow: auto;
  height: 100vh;
  position: fixed !important;
  left: 0;
  top: 0;
  bottom: 0;
`;

export const HeaderBarStyle = styled(Header)`
  padding: 0 !important;
  position: fixed !important;
  top: 0;
  width: 100vw;
  height: 150px;
  background-color: ${color.orange} !important;
  text-align: center;
`;
export const TopBarTool = styled.div`
  width: 100%;
  text-align-last: right;
  justify-content: flex-end;
  align-items: center;
  display: inline;
`;

export const TopBarDropDown = styled.div`
  padding-top: 5px;
  padding-right: 3%;
`;

export const TopBarUser = styled.div`
  text-align-last: right;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  padding-right: 2px;
`;

export const VersionTag = styled.div`
  text-align: center;
  display: ${({ collapse }) => (collapse ? 'none' : 'list-item')};
`;
