import React, { useEffect, useMemo, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { StyledContainer } from '../../components/container/styles';
import { LoadingPage } from '../../components/loading';
import { ClaimInsertForm } from './views/claim-form/ClaimInsertForm';
import { getClaimWarrantyVerify } from '../../services/tanstack-query/queries/claims';
import { useInsertClaim } from './hooks/useClaim';
import { ROUTES_PATH } from '../../resources/routes-name';
import ErrorScene from '../error/index.jsx';
import { setTitle } from '../../redux/title/action';
import { useQueryClient } from '@tanstack/react-query';

export const ClaimScene = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const [isRefetching, setIsRefetching] = React.useState(false);
  const { warrantyId, warrantyCode } = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      warrantyId: parseInt(queryParams.get('id')) || null,
      warrantyCode: queryParams.get('code'),
    };
  }, [location.search]);

  const history = useHistory();
  const {
    data: warrantyData,
    isLoading,
    error,
    refetch,
  } = getClaimWarrantyVerify(warrantyId, warrantyCode);

  const { claimPost } = useInsertClaim({
    onSuccessCallBack: () => {
      const path = `${ROUTES_PATH.ROUTE_HOME.PATH}?tab=claim`;
      history.push(path);
    },
  });

  const handleRefetch = useCallback(async () => {
    setIsRefetching(true);
    await queryClient.removeQueries(['getClaimWarrantyVerify']);
    await refetch();
    setIsRefetching(false);
  }, [queryClient, refetch]);

  useEffect(() => {
    dispatch(setTitle('แจ้งเคลมสินค้า'));
  }, [dispatch]);

  // // effect to refetch when location changes
  useEffect(() => {
    handleRefetch();
  }, [window.location.search, handleRefetch]);

  function handleInsertClaim(value) {
    const data = { ...value, warrantyId };
    claimPost(data);
  }

  if (isLoading || isRefetching) {
    return <LoadingPage loading={true} />;
  }

  if (error) {
    return <ErrorScene />;
  }

  return (
    <StyledContainer>
      <ClaimInsertForm warrantyData={warrantyData} handleSubmitSuccess={handleInsertClaim} />
    </StyledContainer>
  );
};
