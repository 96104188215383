import { Yup } from '../../../helpers/yup';

export const schemaClaimInsert = Yup.object().shape({
  warrantyCode: Yup.string().required(
    'ไม่พบรหัสรับประกัน กรุณาปิดแล้วหน้าเว็บแล้วเข้าใหม่อีกครั้ง',
  ),
  breakdownId: Yup.number().typeError('กรุณาเลือกอาการเสีย').required('กรุณาเลือกอาการเสีย'),
  zipCode: Yup.string()
    .required('กรุณากรอกรหัสไปรษณีย์')
    .matches(/^\d{5}$/, 'รหัสไปรษณีย์ไม่ถูกต้อง'),
  provinceId: Yup.number().typeError('กรุณาเลือกจังหวัด').required('กรุณาเลือกจังหวัด'),
  districtId: Yup.number().typeError('กรุณาเลือกเขต/อำเภอ').required('กรุณาเลือกเขต/อำเภอ'),
  subDistrictId: Yup.number().typeError('กรุณาเลือกแขวง/ตำบล').required('กรุณาเลือกแขวง/ตำบล'),
  other: Yup.string().required('กรุณากรอกรายละเอียดที่อยู่'),
  claimImage: Yup.array()
    .required('กรุณาถ่ายหรือแนบภาพสินค้าที่ต้องการเคลม')
    .min(1, 'กรุณาถ่ายหรือแนบภาพสินค้าที่ต้องการเคลม'),
});
