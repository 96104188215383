import { useHistory } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography, Flex } from 'antd';
import { ButtonTheme } from '../../../../../components/buttons';
import { ImageTheme } from '../../../../../components/images';
import { ROUTES_PATH } from '../../../../../resources/routes-name';
import couponBg from '../../../../../assets/images/coupon-bg.svg';
import { getExpCoupon } from '../../../hooks/useGetStatus';

const { Text } = Typography;
export const CouponContentCard = (props) => {
  const { item } = props;
  const history = useHistory();
  const promotionImageUrl = item && item.promotionImageUrl ? item.promotionImageUrl : '';
  const couponId = item && item.couponId ? item.couponId : 0;
  const couponCode = item && item.couponCode ? item.couponCode : '';

  const title = item && item.title ? item.title : '-';
  const subTitle = item && item.subTitle ? item.subTitle : '';
  const couponType = item && getExpCoupon(item?.couponType);
  const qty = item && item.qty ? item.qty : 0;
  const used = item && item.used ? item.used : 0;
  const isOverUsed = used >= qty ? true : false;

  const handleCouponUseClick = () => {
    const path = `${ROUTES_PATH.ROUTE_COUPON_USED.PATH}?id=${couponId}&code=${couponCode}`;
    history.push(path);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${couponBg})`,
        display: 'flex',
        backgroundSize: '100% 100%',
        width: '100%',
        height: '35vw',
        backgroundRepeat: 'no-repeat',
        padding: '10px 10px 15px 10px',
        opacity: isOverUsed ? 0.5 : 1,
      }}
    >
      <Row gutter={10}>
        <Col className="gutter-row" span={8}>
          <Row style={{ padding: '12px', width: '100%', height: '100%' }}>
            <Flex vertical align="center" justify="center" style={{ height: '100%' }}>
              <ImageTheme src={promotionImageUrl} />
            </Flex>
          </Row>
        </Col>
        <Col className="gutter-row" span={16}>
          <Row gutter={10} style={{ height: '100%', padding: '12px 10px 10px 5px' }}>
            <Col className="gutter-row" span={16}>
              <Flex vertical align="start" justify="space-between" style={{ height: '100%' }}>
                <Row>
                  <Text style={{ fontSize: 12 }}>{title}</Text>
                  <Text style={{ fontSize: 12 }}>{subTitle}</Text>
                </Row>
                <Text style={{ fontSize: 10 }}>{couponType}</Text>
              </Flex>
            </Col>
            <Col className="gutter-row" span={8}>
              <Flex vertical align="end" justify="space-between" style={{ height: '100%' }}>
                <ButtonTheme
                  disabled={isOverUsed}
                  useFor="COUPON"
                  title="ใช้คูปอง"
                  onClick={handleCouponUseClick}
                />
                <Text style={{ fontSize: 16, marginRight: '5px' }}>{`${used}/${qty}`}</Text>
              </Flex>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

CouponContentCard.propTypes = {
  item: PropTypes.shape({
    promotionImageUrl: PropTypes.string,
    couponId: PropTypes.number.isRequired,
    couponCode: PropTypes.string.isRequired,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    couponType: PropTypes.string,
    qty: PropTypes.number,
    used: PropTypes.number,
  }),
};

CouponContentCard.defaultProps = {
  item: {
    promotionImageUrl: '',
    couponId: 0,
    couponCode: '',
    title: '-',
    subTitle: '',
    couponType: '',
    qty: 0,
    used: 0,
  },
};
