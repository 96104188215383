import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row, Typography, Divider, Flex, QRCode, Space } from 'antd';
import { useFormState } from '../../hooks';
import { ButtonTheme } from '../../components/buttons';
import { color } from '../../../../../resources/color';
import { ROUTES_PATH } from '../../../../../resources/routes-name';

export const CouponStoreForm = (props) => {
  const { data } = props;
  const history = useHistory();
  const { defaultValues } = useFormState();
  const [coupon, setCoupon] = useState(defaultValues);

  useEffect(() => {
    setCoupon(data);
  }, []);

  function onClose() {
    const path = `${ROUTES_PATH.ROUTE_HOME.PATH}?tab=coupon`;
    history.push(path);
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: color.orange,
        padding: '20px 15px 15px 20px',
        overflowY: 'auto',
      }}
    >
      <div
        style={{
          backgroundColor: color.content_background,
          paddingTop: '10px',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Flex vertical wrap justify="center" gap="small" style={{ padding: '10px' }}>
          <Typography.Text style={{ fontSize: '20px', color: color.gray }}>
            {coupon && coupon?.title}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '14px', color: color.gray }}>
            {coupon && coupon?.productName}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '16px', color: color.gray }}>
            {`เฉพาะสินค้า ABLEMEN เท่านั้น`}
          </Typography.Text>
        </Flex>
        <Divider variant="dashed" style={{ borderColor: color.orange }} />

        <Space direction="vertical" align="center">
          <QRCode value={(coupon && coupon?.qrCode) || '-'} bordered={false} size={250} />
        </Space>
        <br></br>
        <br></br>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <ButtonTheme useFor="COUPON_USED" title="ปิด" onClick={onClose} />
          </Col>
        </Row>
        <br></br>
        <div
          style={{
            backgroundColor: color.light_gray,
            padding: '20px 10px 15px 10px',
            borderRadius: '0 0 12px 12px',
          }}
        >
          <Row align="center">
            <Typography.Text style={{ fontSize: '14px', color: color.red }}>
              {`กรุณาแสดง QR Code กับร้านค้าที่ร่วมรายการ`}
            </Typography.Text>
          </Row>
          <Row align="center">
            <Typography.Text style={{ fontSize: '14px', color: color.red }}>
              {`เพื่อใช้คูปอง`}
            </Typography.Text>
          </Row>
        </div>
      </div>
    </div>
  );
};

CouponStoreForm.propTypes = {
  data: PropTypes.shape({
    qrCode: PropTypes.string,
    title: PropTypes.string,
    productName: PropTypes.string,
  }),
  handleSubmitSuccess: PropTypes.func.isRequired,
};

CouponStoreForm.defaultProps = {
  data: { qrCode: '', title: '', productName: '' },
  handleSubmitSuccess: () => null,
};
