import { useMutation } from '@tanstack/react-query';
import { POST, POST_SUPERMEMBER } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';
import { openLoading, closeLoading } from '../../../../components/alert/hooks/useAlert';

export const useInsertSuper = () => {
  return useMutation({
    mutationFn: async (formData) => {
      openLoading();
      const response = await POST(POST_SUPERMEMBER(), formData);
      closeLoading();
      return response.result;
    },
    onSuccess: () => {
      openAlert({
        model: 'message',
        type: 'success',
        message: 'ลงทะเบียน Super Member สำเร็จ',
      });
    },
    onError: () => {
      closeLoading();
    },
  });
};
