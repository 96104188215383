import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Row, Col, Typography, Flex } from 'antd';
import { color } from '../../../../../resources/color';
import { ImageTheme } from '../../../../../components/images/index';
import { getClaimStatusTextColor, getClaimStatusBgColor } from '../../../hooks/useGetStatus';

const { Text } = Typography;
export const ClaimContentCard = (props) => {
  const { item } = props;
  const [isHovered, setIsHovered] = useState(false);
  const productImageUrl = item && item.productImageUrl ? item.productImageUrl : '';
  const claimCode = item && item.claimCode ? item.claimCode : '-';
  const warrantyCode = item && item.warrantyCode ? item.warrantyCode : '-';
  const mobileModelName = item && item.mobileModelName ? item.mobileModelName : '-';
  const productName = item && item.productName ? item.productName : '-';
  const expireDate = item && item.expireDate ? moment(item.expireDate).format('DD/MM/YYYY') : '-';
  const fullName = item && item.fullName ? item.fullName : '-';
  const telephone = item && item.telephone ? item.telephone : '-';
  const claimStatusName = item && item.claimStatusName ? item.claimStatusName : 'None';
  const claimStatus = item && item.claimStatus ? item.claimStatus : 'NONE';
  const textColorStatus = getClaimStatusTextColor(claimStatus);
  const bgColorStatus = getClaimStatusBgColor(claimStatus);
  const isShipping = item && item.claimStatus === 'TRANSPORTED' ? true : false;
  const shippingName = item && item.shippingName ? item.shippingName : '-';
  const trackingNumber = item && item.trackingNumber ? item.trackingNumber : '-';

  return (
    <div
      style={{
        borderRadius: '0px 25px 25px 0px',
        padding: '5px 5px 0 0',
        boxSizing: 'border-box',
        lineHeight: '1.5714285714285714',
        backgroundColor: '#ffffff',
        border: '1px solid #f0f0f0',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        marginBottom: '10px',
      }}
    >
      <Row gutter={10} style={{ paddingLeft: '10px' }}>
        <Col className="gutter-row" span={8}>
          <Row>
            <ImageTheme
              src={productImageUrl}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                marginTop: '10px',
              }}
            />
          </Row>
        </Col>
        <Col className="gutter-row" span={16}>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`รหัสรับประกัน:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ color: color.orange, fontSize: 12 }}>
                {warrantyCode}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`รหัสการเคลม:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ color: color.orange, fontSize: 12 }}>
                {claimCode}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`รุ่น:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ fontSize: 12 }}>
                {mobileModelName}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`ชื่อสินค้า:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ fontSize: 12 }}>
                {productName}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`สิ้นสุดประกัน:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ color: color.red, fontSize: 12 }}>{`${expireDate}`}</Text>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col className="gutter-row" span={24}>
              <Flex style={{ width: '100%' }} justify={'end'} align={'center'}>
                <Button type="text" onClick={() => setIsHovered(!isHovered)}>
                  <Text underline style={{ color: color.gray, fontSize: 12 }}>
                    {isHovered ? 'ซ่อน' : `เพิ่มเติม`}
                  </Text>
                </Button>
              </Flex>
            </Col>
          </Row>
          {isHovered && (
            <>
              <Row gutter={10}>
                <Col className="gutter-row" span={10}>
                  <Text style={{ fontSize: 12 }}>{`ชื่อ-นามสกุล:`}</Text>
                </Col>
                <Col className="gutter-row" span={14}>
                  <Text strong style={{ color: color.orange, fontSize: 12 }}>
                    {fullName}
                  </Text>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col className="gutter-row" span={10}>
                  <Text style={{ fontSize: 12 }}>{`เบอร์โทรศัพท์:`}</Text>
                </Col>
                <Col className="gutter-row" span={14}>
                  <Text strong style={{ color: color.orange, fontSize: 12 }}>
                    {telephone}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      <Row
        gutter={10}
        style={{
          marginTop: '15px',
          padding: '10px',
          backgroundColor: bgColorStatus,
          borderRadius: '0 0 25px 0',
        }}
      >
        <Col span={24}>
          <Row>
            <Col span={8} style={{ textAlign: 'start' }}>
              <Text style={{ fontSize: 15 }}>{`สถานะการเคลม: `}</Text>
            </Col>
            <Col span={16}>
              <Text strong style={{ fontSize: 15, color: textColorStatus }}>
                {claimStatusName}
              </Text>
            </Col>
          </Row>
          {isShipping && (
            <Row gutter={10}>
              <Col span={24} style={{ textAlign: 'start' }}>
                <Row>
                  <Col span={8} style={{ textAlign: 'start' }}>
                    <Text style={{ fontSize: 14 }}>{`ขนส่ง: `}</Text>
                    <Text strong style={{ fontSize: 14 }}>
                      {shippingName}
                    </Text>
                  </Col>
                  <Col span={16} style={{ textAlign: 'start' }}>
                    <Text style={{ fontSize: 14 }}>{`เลขพัสดุ: `}</Text>
                    <Text strong style={{ fontSize: 14 }}>
                      {trackingNumber}
                    </Text>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </div>
  );
};

ClaimContentCard.propTypes = {
  item: PropTypes.shape({
    productImageUrl: PropTypes.string,
    claimCode: PropTypes.string,
    warrantyCode: PropTypes.string,
    mobileModelName: PropTypes.string,
    productName: PropTypes.string,
    expireDate: PropTypes.string,
    fullName: PropTypes.string,
    telephone: PropTypes.string,
    claimStatusName: PropTypes.string,
    claimStatus: PropTypes.string,
    shippingName: PropTypes.string,
    trackingNumber: PropTypes.string,
  }),
};

ClaimContentCard.defaultProps = {
  item: null,
};
