import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { StyledContainer } from '../../../../../components/container/styles';
import { RenderForm } from '../../../../../components/forms';
import { FORM_INPUT_CONFIRM_OTP, schemaConfirmOTP } from '../../forms';
import { useFormState } from '../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonTheme } from '../../../../../components/buttons';
import { Col, Row } from 'antd';

export const ConfirmOtpForm = (props) => {
  const { handleSubmitSuccess, otpRef } = props;
  const { confirmOtpDefaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({
    confirmOtpDefaultValues,
    resolver: yupResolver(schemaConfirmOTP),
  });

  function onSubmitSuccess() {
    const values = getValues();
    handleSubmitSuccess(values);
  }

  return (
    <StyledContainer>
      <RenderForm
        control={control}
        forms={FORM_INPUT_CONFIRM_OTP({ otpRef })}
        setValue={setValue}
        getValues={getValues}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTheme
            useFor="CONFIRM_OTP"
            title="ยืนยัน"
            onClick={handleSubmit(onSubmitSuccess)}
          />
        </Col>
      </Row>
    </StyledContainer>
  );
};

ConfirmOtpForm.propTypes = {
  handleSubmitSuccess: PropTypes.func.isRequired,
  otpRef: PropTypes.string,
};

ConfirmOtpForm.defaultProps = {
  handleSubmitSuccess: () => null,
  otpRef: '',
};
