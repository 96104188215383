import React, { useEffect, useState } from 'react';
import { useGetCoupon } from './hooks/useGetCoupon';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../redux/title/action';
import './CouponUsed.css';
import { Divider, Typography } from 'antd';
import { getCouponTypeText } from './func/coupon';
import iconCalendar from '../../assets/images/calendar.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ROUTES_PATH } from '../../resources/routes-name';

export const CouponUsed = () => {
  const [couponData, setCouponData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const id = parseInt(queryParams.get('id'), 10);
  const code = queryParams.get('code');
  const dispatch = useDispatch();
  const history = useHistory();

  const { mutate: onGetData } = useGetCoupon();

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  useEffect(() => {
    if (id && code) {
      setIsLoading(true);
      onGetData(
        { couponId: id, couponCode: code },
        {
          onSuccess: (data) => {
            setCouponData(data.item);
            setIsLoading(false);
          },
          onError: () => {
            setIsLoading(false);
          },
        },
      );
    }
  }, [id, code, onGetData]);

  if (isLoading) {
    return (
      <div className="centered">
        <div className="loader"></div>
      </div>
    );
  }

  const { couponCode, couponId } = couponData;

  const gotoUseCoupon = (type) => {
    history.push(
      `${ROUTES_PATH.ROUTE_COUPON_CONFIRM.PATH}?id=${couponId}&code=${couponCode}&type=${type}`,
    );
  };

  return (
    <div className="container">
      {couponData?.promotionImageUrl && (
        <img src={couponData.promotionImageUrl} alt="Promotion" className="full-width-image" />
      )}

      <div className="details-section">
        <h2 className="title">{couponData?.title}</h2>
        <p className="subtitle">{couponData?.productName}</p>
        <p className="subtitle">
          <img src={iconCalendar} alt="Calendar Icon" className="icon-calendar" />
          {getCouponTypeText(couponData?.couponType)}
        </p>
        <Divider />
        <div className="section-title">เงื่อนไขการรับสิทธิ์</div>
        <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
          {couponData?.terms}
        </Typography.Paragraph>
      </div>

      <div className="button-container">
        <button className="button" onClick={() => gotoUseCoupon('store')}>
          ใช้สิทธิ์ผ่านหน้าสาขา
        </button>
        <button className="button" onClick={() => gotoUseCoupon('web')}>
          ใช้สิทธิ์ผ่านเว็บไซต์
        </button>
      </div>
    </div>
  );
};
