import React from 'react';
import { ConfigProvider } from 'antd';
import { color } from '../../../../../resources/color';
import { ButtonStyle } from './styles';

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = (props) => {
  let { useFor, title } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'COUPON_USED':
      stuff = {
        ...stuff,
        bgcolor: color.navy_blue,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '90%',
          height: '45px',
          borderRadius: '30px',
          fontSize: '20px',
        },
      };
      break;
    case 'COUPON_BACK':
      stuff = {
        ...stuff,
        bgcolor: color.navy_blue,
        style: {
          ...stuff.style,
          width: '90%',
          height: '45px',
          borderRadius: '30px',
          fontSize: '20px',
        },
      };
      break;
    default:
      stuff = {
        ...stuff,
        bgcolor: props.color,
      };
  }

  const { icon, ...rest } = stuff;
  return (
    useFor &&
    !props.hidden && (
      <ConfigProvider
        theme={
          rest.bgcolor && {
            token: {
              colorPrimary: rest.bgcolor,
            },
          }
        }
      >
        <ButtonStyle {...rest} size={rest.size || 'middle'}>
          <div style={{ display: 'flex' }}>
            <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
            <div>&nbsp;{title}</div>
          </div>
        </ButtonStyle>
      </ConfigProvider>
    )
  );
};
