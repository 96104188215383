import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Row, Col, Flex, Space } from 'antd';
import { textStyle } from './styles';
import { ImageTheme } from '../../../components/images';
import { ButtonTheme } from './components/buttons';
import logo from '../../../assets/icons/logo-abm.png';

export const TermWarranty = () => {
  let history = useHistory();
  function onClose() {
    history.goBack();
  }

  return (
    <div style={{ width: '100vw', height: '100%', overflowY: 'auto' }}>
      <Row>
        <Col span={24}>
          <Space direction="vertical" align="center" style={{ width: '100%', marginTop: 20 }}>
            <ImageTheme src={logo} style={{ width: 200 }} />
          </Space>
        </Col>
      </Row>
      <Space direction="vertical" size={16} style={{ width: '100%', padding: '0 40px' }}>
        <Row>
          <Col span={24}>
            <Typography.Text
              strong
              style={textStyle.textTitle}
            >{`การรับประกันสินค้าฟิล์มและกระจกกันรอย Ablemen เงื่อนไขการรับประกันสินค้า`}</Typography.Text>
          </Col>
        </Row>
        <Flex vertical justify="center" style={{ width: '100%' }}>
          <Typography.Text
            style={textStyle.textContent}
          >{`บริษัทดีพลัสอินเตอร์เทรดจำกัด ขอสงวนสิทธิ์ในการลงทะเบียนเคลมสินค้า`}</Typography.Text>
          <Typography.Text
            style={textStyle.textContent}
          >{`1.ลูกค้าต้องกรอกข้อมูลการลงทะเบียนให้ครบถ้วน จึงสามารถใช้สิทธิ์ในการเคลมสินค้า`}</Typography.Text>
          <Typography.Text
            style={textStyle.textContent}
          >{`2.ขอสงวนสิทธิ์ในการเคลมสินค้า เฉพาะกระจกกันรอยของ Ablemen เท่านั้น`}</Typography.Text>
          <Typography.Text
            style={textStyle.textContent}
          >{`3.สินค้าที่ได้รับการรับประกันแล้ว ไม่สามารถนำกลับมารับประกันได้อีก `}</Typography.Text>
        </Flex>

        <Flex justify="center" style={{ width: '100%', marginTop: '15px' }}>
          <ButtonTheme useFor="BACK" title="กลับ" onClick={onClose} />
        </Flex>
      </Space>
    </div>
  );
};
