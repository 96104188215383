import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import { color } from '../../../../resources/color';
import './HomeTabContentView.css';

export const HomeTabContentView = (props) => {
  const { activeKey, onChange, tabContents } = props;
  return (
    <div
      style={{
        width: '100vw',
        height: '100%',
        backgroundColor: color.content_background,
        border: 'none',
        paddingBottom: '15px',
        overflowY: 'auto',
      }}
    >
      <Tabs
        onChange={onChange}
        activeKey={activeKey}
        type="card"
        items={tabContents}
        style={{ paddingRight: '15px' }}
      />
    </div>
  );
};

HomeTabContentView.propTypes = {
  activeKey: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tabContents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      children: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
};
HomeTabContentView.defaultProps = {
  activeKey: '',
  onChange: () => null,
  tabContents: null,
};
