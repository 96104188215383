import React from 'react';
import PropTypes from 'prop-types';
import errorImg from '../../assets/images/error.jpg';
import { ButtonTheme } from '../../components/buttons';
import liff from '@line/liff';
import Swal from 'sweetalert2';

const ErrorScene = (props) => {
  const { message } = props;
  const onCloseForm = () => {
    if (!liff.isInClient()) {
      Swal.fire({
        icon: 'error',
        text: 'External Browser ไม่รองรับการใช้งานนี้',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ปิด',
      });
    } else {
      liff.closeWindow();
    }
  };

  return (
    <div style={{ textAlignLast: 'center', padding: '20px' }}>
      <img src={errorImg} style={{ width: '100%' }} />
      <h3>เกิดข้อผิดพลาด</h3>
      <h3>{`${message || 'กรุณาลองใหม่อีกครั้ง'}`}</h3>
      <ButtonTheme useFor="BACK" title="กลับสู่ Line OA" onClick={onCloseForm} />
    </div>
  );
};
ErrorScene.propTypes = {
  message: PropTypes.string,
};

export default ErrorScene;
