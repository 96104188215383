import React, { useEffect, useMemo } from 'react';
import { Layout, ConfigProvider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getTheme, useTheme } from '../../resources/theme';
import ContentLayout from './layout/content-layout';
import HeaderLayout from './layout/header-layout';
import FooterLayout from './layout/footer-layout';
import { useGetCustomer } from '../../screens/hooks/useGetCustomer';
import { setCookies } from '../../store/useCookies';
import { setInfo } from '../../redux/info/action';
import { loginLineRequest } from '../../redux/line/action';

import { ROUTES_PATH, FULLSCEEN_ROUTES } from '../../resources/routes-name';
import { useHistory } from 'react-router-dom';

const useIsFullScreenRoute = (pathname = '') => {
  return useMemo(() => {
    const currentRoute = Object.values(ROUTES_PATH).find((route) => route.PATH === pathname);
    return Boolean(currentRoute && FULLSCEEN_ROUTES.includes(currentRoute.KEY));
  }, [pathname]);
};

const LayoutPage = () => {
  const { sidebarMode, globalTheme } = useTheme();
  const history = useHistory();
  const isFullScreenRoute = useIsFullScreenRoute(location.pathname);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.line.profile) || {};

  const { mutate: onCallInfo } = useGetCustomer();

  useEffect(() => {
    if (!profile.userId) dispatch(loginLineRequest());
  }, [profile]);

  useEffect(() => {
    if (profile?.userId) {
      onCallInfo(
        { userId: profile.userId },
        {
          onSuccess: (info) => {
            dispatch(setInfo(info?.result?.item));
            setCookies('accessToken', info?.result.item.accessToken);
          },
          onError: (error) => {
            console.log(error);
            const path = ROUTES_PATH.ROUTE_REGISTER_TELEPHONE.PATH;
            console.log(path);
            history.push(path);
          },
        },
      );
    }
  }, [dispatch, profile, onCallInfo]);

  // useEffect(() => {
  //   document.title = title;
  // }, [title]);

  return (
    <ConfigProvider theme={globalTheme}>
      <Layout
        className="site-layout"
        style={{
          height: '100vh',
        }}
      >
        {!isFullScreenRoute && <HeaderLayout />}
        <ContentLayout
          getTheme={getTheme}
          sidebarMode={sidebarMode}
          isFullScreenRoute={isFullScreenRoute}
        />
        <FooterLayout />
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutPage;
