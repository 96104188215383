import { color } from '../../../resources/color';

export const getClaimStatusTextColor = (status) => {
  switch (status) {
    case 'WAIT':
      return color.orange;
    case 'WAIT_PAYMENT':
      return color.orange;
    case 'PREPARING':
      return color.orange;
    case 'TRANSPORTED':
      return color.blue;
    case 'REJECTED':
      return color.red;
    default:
      return color.gray;
  }
};

export const getClaimStatusBgColor = (status) => {
  switch (status) {
    case 'WAIT':
      return color.bg_orange;
    case 'WAIT_PAYMENT':
      return color.bg_orange;
    case 'PREPARING':
      return color.bg_yellow;
    case 'TRANSPORTED':
      return color.bg_blue;
    case 'REJECTED':
      return color.bg_red;
    default:
      return color.bg_gray;
  }
};

export const getExpCoupon = (type) => {
  return type === 'BIRTHDAY' ? 'เฉพาะในเดือนเกิด' : 'ตลอดอายุการเป็นสมาชิก 1 ปี';
};
