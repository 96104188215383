import React from 'react';
import PropTypes from 'prop-types';
import { HomePageStyledContainer } from '../../../../components/container/styles';
import { Badge, Row, Col } from 'antd';
import { ButtonTheme } from '../../../../components/buttons';

export const HomeNavbarView = (props) => {
  const { activeKey, onChange, tabContents } = props;
  const renderNavItem = (key, title, count, index) => (
    <Col className="gutter-row" span={6} key={index}>
      <Badge
        count={count || 0}
        color="geekblue"
        style={{ top: '10px', right: '5px', fontSize: '11px' }}
      >
        <ButtonTheme
          useFor="NAV_ITEM"
          title={title}
          danger={activeKey === key}
          onClick={() => onChange(key)}
        />
      </Badge>
    </Col>
  );

  return (
    <HomePageStyledContainer>
      <Row>
        {tabContents.map((item, index) => renderNavItem(item.key, item.title, item.count, index))}
      </Row>
    </HomePageStyledContainer>
  );
};

HomeNavbarView.propTypes = {
  activeKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabContents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      count: PropTypes.number,
    }),
  ).isRequired,
};
HomeNavbarView.defaultProps = {
  activeKey: '',
  onChange: () => null,
  tabContents: null,
};
