import React from 'react';
import { ConfigProvider, Tooltip } from 'antd';
import { color } from '../../resources/color';
import { Icon } from '../../resources/icon';
import { ButtonIconStyle, ButtonStyle } from './styles';

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = (props) => {
  let { useFor, title } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'SEARCH':
      title = 'ค้นหา';
      stuff = {
        ...stuff,
        bgcolor: color.search,
        icon: <Icon.search />,
        type: 'primary',
      };
      break;
    case 'EDIT':
      title = 'แก้ไข';
      stuff = {
        ...stuff,
        bgcolor: color.edit,
        icon: <Icon.edit />,
        type: 'primary',
      };
      break;
    case 'REMOVE':
      title = 'ลบ';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.remove />,
        type: 'primary',
      };
      break;
    case 'VIEW':
      title = title || 'ดูข้อมูล';
      stuff = {
        ...stuff,
        icon: <Icon.view />,
        type: 'primary',
      };
      break;
    case 'CLEAR':
      title = 'ล้างข้อมูล';
      stuff = {
        ...stuff,
        icon: <Icon.clear />,
        type: 'default',
      };
      break;
    case 'DOWNLOAD':
      title = title || 'ดาว์นโหลด';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'PRINT':
      title = title || 'พิมพ์';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        icon: <Icon.print />,
        type: 'primary',
      };
      break;
    case 'SUBMIT':
      title = 'บันทึก';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.save />,
        type: 'primary',
      };
      break;
    case 'CONFIRM':
      title = title || 'ยืนยัน';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.confirm />,
        type: 'primary',
      };
      break;
    case 'CANCEL':
      title = 'ยกเลิก';
      stuff = {
        ...stuff,
        icon: <Icon.cancel />,
        type: 'default',
      };
      break;
    case 'LOGIN':
      title = 'เข้าสู่ระบบ';
      stuff = {
        ...stuff,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'LOGOUT':
      stuff = {
        ...stuff,
        bgcolor: color.logout,
        icon: <Icon.login />,
        type: 'primary',
      };
      break;
    case 'FORGET_PASSWORD':
      title = 'ลืมรหัสผ่าน';
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        type: 'default',
      };
      break;
    case 'BACK':
      title = title || 'ย้อนกลับ';
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'default',
      };
      break;
    case 'SWITCH':
      stuff = {
        ...stuff,
        icon: <Icon.back />,
        type: 'primary',
      };
      break;
    case 'CREATE':
      stuff = {
        ...stuff,
        bgcolor: color.search,
        icon: <Icon.create />,
        type: 'primary',
      };
      break;
    case 'ZIP_XML':
      stuff = {
        ...stuff,
        icon: <Icon.download />,
        type: 'primary',
      };
      break;
    case 'RESEND':
      stuff = {
        ...stuff,
        icon: <Icon.reAct />,
        type: 'default',
      };
      break;
    case 'REQ_PASSWORD':
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        bgcolor: color.search,
        type: 'primary',
      };
      break;
    case 'RE_CHECK':
      stuff = {
        ...stuff,
        icon: <Icon.recheck />,
        bgcolor: color.edit,
        type: 'primary',
      };
      break;
    case 'REGISTER':
      stuff = {
        ...stuff,
        icon: <Icon.register />,
        bgcolor: color.submit,
        type: 'primary',
      };
      break;
    case 'SUPERMEMBER':
      title = 'ลงทะเบียน Super Member';
      stuff = {
        ...stuff,
        bgcolor: color.search,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '60%',
        },
      };
      break;
    case 'REGISTER_WARRANTY':
      title = 'ลงทะเบียน';
      stuff = {
        ...stuff,
        bgcolor: color.orange,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '60%',
        },
      };
      break;
    case 'OK':
      title = 'ตกลง';
      stuff = {
        ...stuff,
        bgcolor: color.submit,
        icon: <Icon.save />,
        type: 'primary',
      };
      break;
    case 'SEND_OTP':
      stuff = {
        ...stuff,
        icon: <Icon.mobile />,
        bgcolor: color.orange,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '60%',
        },
      };
      break;
    case 'CONFIRM_OTP':
      stuff = {
        ...stuff,
        icon: <Icon.confirm />,
        bgcolor: color.otp_confirm,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '60%',
        },
      };
      break;
    case 'NAV_ITEM':
      stuff = {
        ...stuff,
        bgcolor: props.color,
        style: {
          ...stuff.style,
          width: '80px',
          height: '25px',
          borderRadius: '25px',
          padding: '5px',
          fontSize: '11px',
        },
      };
      break;
    case 'CLAIM':
      stuff = {
        ...stuff,
        bgcolor: color.orange,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '50%',
        },
      };
      break;

    case 'WARRANTY_ADD':
      stuff = {
        ...stuff,
        bgcolor: color.orange,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '50%',
        },
      };
      break;
    case 'COUPON':
      stuff = {
        ...stuff,
        bgcolor: color.orange,
        type: 'primary',
        style: {
          ...stuff.style,
          width: '100%',
          borderRadius: '30px',
          padding: '5px 10px',
          fontSize: '12px',
        },
      };
      break;
    default:
      stuff = {
        ...stuff,
        bgcolor: props.color,
      };
  }

  const { icon, ...rest } = stuff;
  return (
    useFor &&
    !props.hidden && (
      <ConfigProvider
        theme={
          rest.bgcolor && {
            token: {
              colorPrimary: rest.bgcolor,
            },
          }
        }
      >
        <ButtonStyle {...rest} size={rest.size || 'middle'}>
          <div style={{ display: 'flex' }}>
            <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
            <div>&nbsp;{title}</div>
          </div>
        </ButtonStyle>
      </ConfigProvider>
    )
  );
};

export const ButtonIconTheme = (props) => {
  return renderButtonIcon(props);
};

const renderButtonIcon = (props) => {
  let { useFor, text } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'EDIT':
      text = text || 'แก้ไข';
      stuff = {
        ...stuff,
        color: color.edit,
        icon: <Icon.edit />,
      };
      break;
    case 'REMOVE':
      text = text || 'ลบ';
      stuff = {
        ...stuff,
        color: color.remove,
        icon: <Icon.remove />,
      };
      break;
    case 'CANCEL':
      text = text || 'ยกเลิก';
      stuff = {
        ...stuff,
        bgcolor: color.remove,
        color: 'white',
        icon: <Icon.cancel />,
      };
      break;
    case 'SEARCH':
      text = text || 'ค้นหา';
      stuff = {
        ...stuff,
        color: color.drak_gray,
        icon: <Icon.search />,
      };
      break;
    case 'DOWNLOAD':
      text = text || 'ดาว์นโหลด';
      stuff = {
        ...stuff,
        color: color.submit,
        icon: <Icon.download />,
      };
      break;
    case 'PRINT':
      text = text || 'พิมพ์';
      stuff = {
        ...stuff,
        color: color.remove,
        icon: <Icon.print />,
      };
      break;
    case 'RESEND':
      stuff = {
        ...stuff,
        color: 'black',
        icon: <Icon.reAct />,
      };
      break;
    case 'REQ_PASSWORD':
      stuff = {
        ...stuff,
        icon: <Icon.forgetPassword />,
        color: color.search,
      };
      break;

    case 'DISCOUNT':
      stuff = {
        ...stuff,
        icon: <Icon.currency style={{ width: '30px', height: '30px', display: 'flex' }} />,
        color: color.orange,
        size: 'large',
      };
      break;
    case 'BIRTHDAY':
      stuff = {
        ...stuff,
        icon: <Icon.birthday style={{ width: '30px', height: '30px', display: 'flex' }} />,
        color: color.orange,
        size: 'large',
      };
      break;
    case 'REDEEM':
      stuff = {
        ...stuff,
        icon: <Icon.gift style={{ width: '30px', height: '30px', display: 'flex' }} />,
        color: color.orange,
        size: 'large',
      };
      break;
    case 'SERVICE':
      stuff = {
        ...stuff,
        icon: <Icon.fix style={{ width: '30px', height: '30px', display: 'flex' }} />,
        color: color.orange,
        size: 'large',
      };
      break;
    case 'ALL':
      stuff = {
        ...stuff,
        icon: <Icon.coupon style={{ width: '30px', height: '30px', display: 'flex' }} />,
        color: color.orange,
        size: 'large',
      };
      break;
  }
  const { icon, ...rest } = stuff;
  return (
    useFor && (
      <Tooltip placement="top" title={text}>
        <span style={{ padding: '2px' }}>
          <ButtonIconStyle shape="circle" size={stuff.size || 'middle'} {...rest}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
            </div>
          </ButtonIconStyle>
        </span>
      </Tooltip>
    )
  );
};
