import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import { useFormState, useUpdateCouponUsedPut } from './hooks';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { setTitle } from '../../../redux/title/action';
import { CouponMainForm } from './views/main/CouponMainForm';
import { CouponWebForm } from './views/web/CouponWebForm';
import { CouponStoreForm } from './views/store/CouponStoreForm';

export const CouponConfirmScene = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { defaultValues } = useFormState();
  const [coupon, setCoupon] = useState(defaultValues);
  const [active, setActive] = useState('MAIN');
  const { couponId, couponCode, type } = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return {
      couponId: parseInt(queryParams.get('id')) || null,
      couponCode: queryParams.get('code'),
      type: queryParams.get('type'),
    };
  }, [location.search]);

  const redirectToHomePageTabCoupon = () => {
    history.push(`${ROUTES_PATH.ROUTE_HOME.PATH}?tab=coupon`);
  };

  const callbacks = useMemo(
    () => ({
      onVerifySuccessCallBack: (data) => {
        setCoupon(data);
      },
      onVerifyErrorCallBack: async (error) => {
        Swal.fire({
          icon: 'error',
          text: error?.message,
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'ปิด',
        }).then((result) => {
          if (result.isConfirmed) {
            redirectToHomePageTabCoupon();
          }
        });
      },
      onUsedSuccessCallBack: () => {
        setActive(type.toUpperCase());
      },
    }),
    [history],
  );

  const { couponHook } = useUpdateCouponUsedPut(callbacks);

  useEffect(() => {
    dispatch(setTitle('ยืนยันการใช้คูปอง'));
    if (couponId && couponCode) {
      couponHook({ couponId, couponCode }, 'verify');
    }
  }, [dispatch, couponId, couponCode]);

  function handleCouponUsed() {
    // ถ้าลูกค้าเลือกเว็บให้ update สถานะการใช้ coupon
    const upperType = type?.toUpperCase();
    if (upperType === 'WEB') {
      const data = {
        couponId: coupon.couponId,
        couponCode: coupon.couponCode,
        uuid: coupon.uuid,
        qtyUsed: 1,
      };
      couponHook(data, 'used');
      setActive(type.toUpperCase());
    } else {
      setActive(upperType);
    }
  }
  const renderCouponForm = useMemo(() => {
    if (!coupon) return null;
    const forms = {
      MAIN: <CouponMainForm data={coupon} handleSubmitSuccess={handleCouponUsed} />,
      WEB: <CouponWebForm data={coupon} />,
      STORE: <CouponStoreForm data={coupon} />,
    };

    return forms[active] || forms.MAIN;
  }, [coupon, active]);

  return <div style={{ height: '100%', width: '100%' }}>{renderCouponForm}</div>;
};
