import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { serviceOptions } from '../../../../functions/serviceOptions';

export const useProductOptions = (mobileBrandId, mobileModelId, setValue) => {
  const { items } = useSelector((state) => state.warranty) || {};
  const [brandOptions, setBrandOptions] = useState([]);

  useEffect(() => {
    const brands = (items && items[0]?.mobilebrands) || [];
    setBrandOptions(serviceOptions('MOBILE_BRAND', brands));
  }, [items]);

  const modelOptions = useMemo(() => {
    setValue('mobileModelId', null);
    const selectedBrand =
      items && items[0]?.mobilebrands?.find((el) => el?.mobileBrandId === mobileBrandId);
    return mobileBrandId ? serviceOptions('MOBILE_MODEL', selectedBrand?.mobileModels || []) : [];
  }, [mobileBrandId, items]);

  const productOptions = useMemo(() => {
    setValue('mapProductId', null);
    const selectedBrand =
      items && items[0]?.mobilebrands?.find((el) => el?.mobileBrandId === mobileBrandId);
    const selectedModel = selectedBrand?.mobileModels?.find(
      (el) => el.mobileModelId === mobileModelId,
    );
    const products = selectedModel?.products;
    return mobileModelId ? serviceOptions('PRODUCTS', products || []) : [];
  }, [mobileBrandId, mobileModelId, modelOptions]);

  return { productOptions, brandOptions, modelOptions };
};
