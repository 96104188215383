export const textStyle = {
  img: {
    width: '80vw',
    height: '80vw',
    objectFit: 'cover',
  },
  imgBox: {
    borderRadius: '5px',
    backgroundColor: '#f5f5f5',
    padding: '10px',
    marginTop: '15px',
  },
  content: {
    color: 'red',
    fontSize: '18px',
    textAlign: 'center',
  },
};
