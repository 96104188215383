import React, { useState, useEffect } from 'react';
import { Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StyledContainer } from '../../../components/container/styles';
import { useModal } from '../../../components/modal/hooks/useModal';
import { PolicyModal } from './views/policy-modal/PolicyModal';
import { RequestOtpForm } from './views/request-otp-form/RequestOtpForm';
import { TimerForm } from './views/timer-form/TimerForm';
import { ConfirmOtpForm } from './views/confirm-otp-form/ConfirmOtpForm';
import { getPolicyPDPAQuery } from '../../../services/tanstack-query/queries/policies';
import { openAlert } from '../../../components/alert/hooks';
import { useRegisterTel } from './hooks/useRegisterTel';
import { ROUTES_PATH } from '../../../resources/routes-name';
import { useFormState } from './hooks/useFormState';
import { setTitle } from '../../../redux/title/action';
import { useGetCustomer } from '../../../screens/hooks/useGetCustomer';
import { setCookies } from '../../../store/useCookies';
import { setInfo } from '../../../redux/info/action';
import ErrorScene from '../../error/index.jsx';

export const RegisterTelScene = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.line.profile);
  const history = useHistory();
  const { open, onToggle } = useModal();
  const { submitOtpDefaultValues } = useFormState();
  const { data: pdpaResult, isLoading, error } = getPolicyPDPAQuery();
  const [isComfirmOtp, setConfirmOtp] = useState(false);
  const [otpRequest, setOtpRequest] = useState(submitOtpDefaultValues);
  const { mutate: onCallInfo } = useGetCustomer();
  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนเบอร์โทรศัพท์'));
  }, [dispatch]);

  const { registerPost } = useRegisterTel({
    onRequestOtpSuccessCallBack: (response) => {
      setOtpRequest((prev) => ({
        ...prev,
        ...response,
      }));
      setConfirmOtp(true);
    },
    onConfirmOtpSuccessCallBack: (response) => {
      setOtpRequest((prev) => ({
        ...prev,
        ...response,
      }));
      fetchCustomerInfo();
    },
  });

  useEffect(() => {
    onToggle(true);
  }, []);

  function handlePdpaSummit(value) {
    setOtpRequest((prev) => ({
      ...prev,
      isPolicyAccept: value,
    }));
    onToggle(false);
  }

  function handleSubmitTelephone(values) {
    registerPost(values, 'REQUEST');
  }
  function handleResendOtp() {
    setConfirmOtp(false);
  }

  function handleSubmitOTP(values) {
    const data = {
      otpCode: values?.otp,
      otpRef: otpRequest?.otpRef,
      telephone: otpRequest?.telephone,
      isPolicyAccept: otpRequest?.isPolicyAccept,
      lineUid: userProfile?.userId,
      lineName: userProfile?.displayName,
    };
    registerPost(data, 'CONFIRM');
    setConfirmOtp(true);
    setOtpRequest((prev) => ({
      ...prev,
      ...values,
    }));
  }

  function fetchCustomerInfo() {
    if (userProfile?.userId) {
      onCallInfo(
        { userId: userProfile.userId },
        {
          onSuccess: (info) => {
            dispatch(setInfo(info?.result?.item));
            setCookies('accessToken', info?.result.item.accessToken);
            history.push(ROUTES_PATH.ROUTE_HOME.PATH);
          },
          onError: (err) => {
            console.log(err);
          },
        },
      );
    }
  }

  if (isLoading) {
    return <Skeleton active />;
  }

  if (error) {
    openAlert({
      type: 'error',
      message: error.error || 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
    });
    return <ErrorScene />;
  }

  return (
    <StyledContainer style={{ width: '100%' }}>
      {open && (
        <PolicyModal
          open={open}
          onToggle={onToggle}
          policyText={pdpaResult}
          handleSubmitSuccess={handlePdpaSummit}
        />
      )}
      <RequestOtpForm disabledBtn={isComfirmOtp} handleSubmitSuccess={handleSubmitTelephone} />
      {isComfirmOtp && <TimerForm handleResend={handleResendOtp} />}
      {isComfirmOtp && (
        <ConfirmOtpForm
          handleSubmitSuccess={handleSubmitOTP}
          otpRef={otpRequest ? otpRequest.otpRef : ''}
        />
      )}
    </StyledContainer>
  );
};
