import React from 'react';
import PropTypes from 'prop-types';
import { Row, Typography, Flex } from 'antd';
import { ButtonIconTheme } from '../../../../../components/buttons';

const { Text } = Typography;
export const CouponNavbarView = (props) => {
  const { activeKey, onChange, tabContents } = props;
  const renderNavItem = (key, title, index) => (
    <div key={index} style={{ width: '20%' }}>
      <Flex vertical align="center">
        <ButtonIconTheme useFor={key} danger={activeKey === key} onClick={() => onChange(key)} />
        <Text style={{ fontSize: 16 }}>{title}</Text>
      </Flex>
    </div>
  );

  return (
    <Row gutter={10} style={{ padding: '5px 10px 15px 20px' }}>
      <Flex vertical style={{ width: '100%' }}>
        <Text strong style={{ fontSize: 18 }}>
          หมวดหมู่
        </Text>
        <Row style={{ marginTop: '10px' }}>
          {tabContents?.map((item, index) => renderNavItem(item.key, item.title, index))}
        </Row>
      </Flex>
    </Row>
  );
};

CouponNavbarView.propTypes = {
  activeKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tabContents: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CouponNavbarView.defaultProps = {
  activeKey: 'SERVICE',
  onChange: () => null,
  tabContents: null,
};
