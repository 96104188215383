import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { StyledContainer } from '../../../../../components/container/styles';
import { RenderForm } from '../../../../../components/forms';
import { FORM_INPUT_REQUEST_OTP, schemaRequestOTP } from '../../forms';
import { useFormState } from '../../hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { ButtonTheme } from '../../../../../components/buttons';
import { Col, Row } from 'antd';

export const RequestOtpForm = (props) => {
  const { disabledBtn, handleSubmitSuccess } = props;
  const { requestOtpDefaultValues } = useFormState();
  const { control, setValue, getValues, handleSubmit } = useForm({
    requestOtpDefaultValues,
    resolver: yupResolver(schemaRequestOTP),
  });

  function onSubmitSuccess() {
    const values = getValues();
    handleSubmitSuccess(values);
  }

  return (
    <StyledContainer>
      <RenderForm
        control={control}
        forms={FORM_INPUT_REQUEST_OTP()}
        setValue={setValue}
        getValues={getValues}
      />
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTheme
            disabled={disabledBtn}
            useFor="SEND_OTP"
            title="รับรหัส OTP"
            onClick={handleSubmit(onSubmitSuccess)}
          />
        </Col>
      </Row>
    </StyledContainer>
  );
};

RequestOtpForm.propTypes = {
  disabledBtn: PropTypes.bool,
  handleSubmitSuccess: PropTypes.func.isRequired,
};

RequestOtpForm.defaultProps = {
  disabledBtn: false,
  handleSubmitSuccess: () => null,
};
