import { Yup } from '../../../../helpers/yup';

export const schemaPolicy = Yup.object().shape({
  policyCheck: Yup.boolean()
    .typeError('กรุณายอมรับนโยบายและเงื่อนไขการให้บริการ')
    .required('กรุณายอมรับนโยบายและเงื่อนไขการให้บริการ'),
});

export const schemaRequestOTP = Yup.object().shape({
  telephone: Yup.string()
    .required('กรุณากรอกเบอร์โทรศัพท์')
    .matches(/^0\d{9}$/, 'เบอร์โทรศัพท์ไม่ถูกต้อง'),
});

export const schemaConfirmOTP = Yup.object().shape({
  otp: Yup.string()
    .required('กรุณากรอกรหัส OTP')
    .matches(/^\d{6}$/, 'รหัส OTP ไม่ถูกต้อง'),
});
