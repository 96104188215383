import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormState } from './hooks/useFormState';
import { HomeNavbarView } from './views/navbar/HomeNavbarView';
import { HomeTabContentView } from './views/tab/HomeTabContentView';
import { getItemsQuery } from '../../services/tanstack-query/queries/items';
import ErrorScene from '../error/index.jsx';
import { WarrantiesView } from './views/contents/warranties/WarrantiesView';
import { ClaimsView } from './views/contents/claims/ClaimsView';
import { CouponsView } from './views/contents/coupons/CouponsView';
import { useDispatch, useSelector } from 'react-redux';
import { setTitle } from '../../redux/title/action';
import { LoadingPage } from '../../components/loading';

export const HomeScene = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams(window.location.search);
  const tab = useMemo(() => queryParams.get('tab'), [queryParams]);
  const userProfile = useSelector((state) => state.info);

  const { data, isLoading, error, refetch } = getItemsQuery(userProfile?.lineUid);
  const { tabContents, tabActive } = useFormState({
    tab,
    warrantyScene: null,
    claimScene: null,
    couponScene: null,
  });
  const [activeKey, setActiveKey] = useState(tabActive?.key);
  const [isRefetching, setIsRefetching] = React.useState(true);

  const handleRefetch = useCallback(async () => {
    setIsRefetching(true);
    await queryClient.removeQueries(['getItemsQuery']);
    await refetch();
    setIsRefetching(false);
  }, [queryClient, refetch]);

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  // effect to refetch when location changes
  useEffect(() => {
    if (userProfile && userProfile.lineUid) handleRefetch();
  }, [window.location.search, handleRefetch, userProfile]);

  const contents = useMemo(() => {
    if (!data) return tabContents;
    let contentList = [
      {
        ...tabContents[0],
        count: data.summary?.totalWarranty ?? 0,
        children: <WarrantiesView items={data.warranties} />,
      },
      {
        ...tabContents[1],
        count: data.summary?.totalClaim ?? 0,
        children: <ClaimsView items={data.claims} />,
      },
    ];
    if (userProfile && userProfile.customerLevel === 'MEMBER') {
      contentList.push({
        ...tabContents[2],
        count: data.summary?.totalCoupon ?? 0,
        children: <CouponsView items={data.coupons} />,
      });
    }
    return contentList;
  }, [data, tabContents]);

  const onChange = useCallback((key) => {
    setActiveKey(key);
  }, []);

  if (isLoading || isRefetching) {
    return <LoadingPage loading={true} />;
  }

  if (error) {
    return <ErrorScene />;
  }

  return (
    <div>
      <HomeNavbarView activeKey={activeKey} onChange={onChange} tabContents={contents} />
      <HomeTabContentView activeKey={activeKey} onChange={onChange} tabContents={contents} />
    </div>
  );
};
