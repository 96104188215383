import { useMutation } from '@tanstack/react-query';
import { openLoading, closeLoading } from '../../../../components/alert/hooks/useAlert';
import { POST, POST_REQUEST_OTP, POST_CONFIRM_OTP } from '../../../../services';
import { openAlert } from '../../../../components/alert/hooks';

export const useRegisterTel = (props) => {
  const { onRequestOtpSuccessCallBack = () => null, onConfirmOtpSuccessCallBack = () => null } =
    props;

  const requestOtpPost = useMutation({
    mutationFn: async (data) => {
      const response = await POST(POST_REQUEST_OTP, data);

      closeLoading();
      if (response?.success) return response?.result?.item;
    },
    onSuccess: (data) => {
      onRequestOtpSuccessCallBack(data);
    },
    onError: (error) => {
      openAlert({ model: 'modal', type: 'error', message: error.message });
    },
  });

  const confirmOtpPost = useMutation({
    mutationFn: async (data) => {
      const response = await POST(POST_CONFIRM_OTP, data);

      closeLoading();
      if (response?.success) return response?.result?.item;
    },
    onSuccess: (data) => {
      openAlert({ model: 'message', type: 'success', message: 'ลงทะเบียนเบอร์โทรศัพท์สำเร็จ' });
      onConfirmOtpSuccessCallBack(data);
    },
    onError: (error) => {
      openAlert({ model: 'modal', type: 'error', message: error?.message });
    },
  });

  const registerPost = (data, type) => {
    openLoading();
    if (type === 'REQUEST') {
      requestOtpPost.mutate(data);
    } else {
      confirmOtpPost.mutate(data);
    }
  };

  return {
    registerPost,
  };
};
