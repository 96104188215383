import React from 'react';
import Pages from '../screens/all-page';
import { Icon } from './icon';

export const ROUTE_LOGIN = 'https://central.zetta-system.com';
export const ROUTE_LOGIN_MANUAL = '/account/login';
export const ROUTE_HANDLE = '/account/handle';

export const KEY_ROUTE_MAIN = '@ROUTES/ROUTE_MAIN';
export const KEY_ROUTE_HOME = '@ROUTES/ROUTE_HOME';
export const KEY_ROUTE_DASHBOARD = '@ROUTES/ROUTE_DASHBOARD';
export const KEY_ROUTE_TO_DO_LIST = '@ROUTES/ROUTE_TO_DO_LIST';
export const KEY_ROUTE_REGISTER = '@ROUTES/ROUTE_REGISTER';
export const KEY_ROUTE_REGISTER_INFO = '@ROUTES/ROUTE_REGISTER_INFO';
export const KEY_ROUTE_REGISTER_TELEPHONE = '@ROUTES/ROUTE_REGISTER_TELEPHONE';
export const KEY_ROUTE_CLAIM_REGISTER = '@ROUTES/ROUTE_CLAIM_REGISTER';
export const KEY_ROUTE_COUPON_USED = '@ROUTES/ROUTE_COUPON_USED';
export const KEY_ROUTE_COUPON_CONFIRM = '@ROUTES/ROUTE_COUPON_CONFIRM';
export const KEY_ROUTE_PROFILE = '@ROUTES/ROUTE_PROFILE';
export const KEY_ROUTE_TERM_WARRANTY = '@ROUTES/KEY_ROUTE_TERM_WARRANTY';

function renderStyle(comp) {
  return <div style={{ marginRight: '1px' }}>{comp}</div>;
}

export const ROUTES_PATH = {
  ROUTE_MAIN: {
    KEY: KEY_ROUTE_MAIN,
    PATH: '/',
    LABEL: 'หน้าหลัก',
    COMPONENT: Pages.HomeScene,
  },
  ROUTE_HOME: {
    KEY: KEY_ROUTE_HOME,
    PATH: '/home',
    LABEL: 'หน้าหลัก',
    COMPONENT: Pages.HomeScene,
  },
  ROUTE_REGISTER: {
    KEY: KEY_ROUTE_REGISTER,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/warranty',
    LABEL: 'สมัครสมาชิก',
    COMPONENT: Pages.Warranty,
  },
  ROUTE_REGISTER_INFO: {
    KEY: KEY_ROUTE_REGISTER_INFO,
    ICON: renderStyle(<Icon.dashboard />),
    PATH: '/register-info',
    LABEL: 'register-info',
    COMPONENT: Pages.RegisterInfo,
  },
  ROUTE_REGISTER_TELEPHONE: {
    KEY: KEY_ROUTE_REGISTER_TELEPHONE,
    PATH: '/register/telephone',
    LABEL: 'ลงทะเบียนเบอร์มือถือ',
    COMPONENT: Pages.RegisterTelScene,
  },
  ROUTE_CLAIM_REGISTER: {
    KEY: KEY_ROUTE_CLAIM_REGISTER,
    PATH: '/claim/register',
    LABEL: 'ลงทะเบียนเคลม',
    COMPONENT: Pages.ClaimScene,
  },
  ROUTE_COUPON_USED: {
    KEY: KEY_ROUTE_COUPON_USED,
    PATH: '/coupon/used',
    LABEL: 'คูปอง',
    COMPONENT: Pages.CouponUsed,
  },
  ROUTE_COUPON_CONFIRM: {
    KEY: KEY_ROUTE_COUPON_CONFIRM,
    PATH: '/coupon/confirm',
    LABEL: 'ยืนยันใช้คูปอง',
    COMPONENT: Pages.CouponConfirmScene,
  },
  ROUTE_PROFILE: {
    KEY: KEY_ROUTE_PROFILE,
    PATH: '/profile',
    LABEL: 'ข้อมูลลูกค้า',
    COMPONENT: Pages.ProfileScene,
  },
  ROUTE_TERM_WARRANTY: {
    KEY: KEY_ROUTE_TERM_WARRANTY,
    PATH: '/term/warranty',
    LABEL: 'ข้อตกลงการรับประกันสินค้า',
    COMPONENT: Pages.TermWarranty,
  },
};

function getKeyLowerCase(obj) {
  return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k.toLowerCase(), v]));
}

export const ROUTES_MENU = [getKeyLowerCase(ROUTES_PATH.ROUTE_REGISTER)];

export const FULLSCEEN_ROUTES = [
  ROUTES_PATH.ROUTE_COUPON_CONFIRM.KEY,
  ROUTES_PATH.ROUTE_COUPON_USED.KEY,
  ROUTES_PATH.ROUTE_PROFILE.KEY,
];
