export const useFormState = () => {
  const policyDefaultValues = {
    policyCheck: [false],
  };
  const requestOtpDefaultValues = {
    telephone: '',
  };
  const confirmOtpDefaultValues = {
    otp: '',
  };
  const submitOtpDefaultValues = {
    otpRef: '',
    telephone: '',
    isPolicyAccept: '',
  };

  return {
    policyDefaultValues,
    requestOtpDefaultValues,
    confirmOtpDefaultValues,
    submitOtpDefaultValues,
  };
};
