import React, { useCallback, useState, useEffect } from 'react';
import { Input, Flex, message, Spin } from 'antd';
import { QrcodeOutlined } from '@ant-design/icons';
import liff from '@line/liff';
import { useDispatch } from 'react-redux';
import { setTitle } from '../../../redux/title/action';
import { FORM_INPUT } from './forms';
import { ButtonIconTheme } from './components/buttons';
import { useVerifyNumber } from './hooks/useVerifyNumber';
import { useForm } from 'react-hook-form';
import { setWarrantyData } from '../../../redux/warranty/actions';

export const Warranty = () => {
  const { reset, getValues } = useForm();
  const [warrantyCode, setWarrantyCode] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { mutate: onCallTele } = useVerifyNumber({ reset, getValues, setLoading });

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  const onSearch = (warrantyNumberCode) => {
    if (warrantyNumberCode) {
      setLoading(true);
      onCallTele(
        { warrantyNumberCode },
        {
          onSuccess: (data) => {
            dispatch(setWarrantyData(data));
          },
        },
      );
    }
  };

  const extractWarrantyCode = (url) => {
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get('fw') || '';
  };

  const handleQRCodeScan = useCallback(async () => {
    try {
      const osType = liff.getOS();
      let result;
      if (osType === 'ios') {
        result = await liff.scanCodeV2();
      } else {
        result = await liff.scanCode();
      }
      if (result?.value) {
        const code = extractWarrantyCode(result.value);
        if (code) {
          onSearch(code);
          setWarrantyCode(code);
        } else {
          message.warning('Invalid QR Code format. Please scan a valid QR Code.');
        }
      }
    } catch (error) {
      message.error('QR Code scan failed. Please try again.');
    }
  }, []);

  return (
    <Spin spinning={loading}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '20px 16px',
          alignItems: 'center',
          marginBottom: '20px',
          textAlign: 'center',
          width: '100vw',
        }}
      >
        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <div>
            <h2>กดถ่ายภาพ</h2>
          </div>
          <h2>รหัส QR รับประกัน</h2>
        </Flex>
        <QrcodeOutlined
          style={{
            fontSize: '120px',
            cursor: 'pointer',
            border: '1px solid #ccc',
            padding: '3px',
            borderRadius: '6px',
          }}
          onClick={handleQRCodeScan}
        />
        <br></br>
        <p>หรือกรอกรหัสรับประกัน</p>

        <Flex justify="center" align="center" style={{ width: '100%' }}>
          <div>
            {FORM_INPUT().map((input) => (
              <Input
                key={input.name}
                placeholder={input.placeholder}
                value={warrantyCode}
                onChange={(e) => setWarrantyCode(e.target.value)}
                style={{ padding: '10px' }}
              />
            ))}
          </div>
          <ButtonIconTheme useFor="SEARCH" onClick={() => onSearch(warrantyCode)} />
        </Flex>
      </div>
    </Spin>
  );
};
