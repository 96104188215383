import React from 'react';
import { ConfigProvider, Tooltip } from 'antd';
import { color } from '../../../../../resources/color';
import { Icon } from '../../../../../resources/icon';
import { ButtonStyle, ButtonIconStyle } from './styles';

export const ButtonTheme = (props) => {
  return renderButtonType(props);
};

const renderButtonType = (props) => {
  let { useFor, title } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'SEARCH':
      stuff = {
        ...stuff,
        bgcolor: color.clear,
        icon: <Icon.search />,
        type: 'primary',
        size: 'large',
      };
      break;
    default:
      stuff = {
        ...stuff,
        bgcolor: props.color,
      };
  }

  const { icon, ...rest } = stuff;
  return (
    useFor &&
    !props.hidden && (
      <ConfigProvider
        theme={
          rest.bgcolor && {
            token: {
              colorPrimary: rest.bgcolor,
            },
          }
        }
      >
        <ButtonStyle {...rest} size={rest.size || 'middle'}>
          <div style={{ display: 'flex' }}>
            <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
            <div>&nbsp;{title}</div>
          </div>
        </ButtonStyle>
      </ConfigProvider>
    )
  );
};

export const ButtonIconTheme = (props) => {
  return renderButtonIcon(props);
};

const renderButtonIcon = (props) => {
  let { useFor, text } = props;
  let stuff = {
    htmlType: props.htmlType,
    style: { ...props.style },
    ...props,
  };

  switch (useFor) {
    case 'SEARCH':
      stuff = {
        ...stuff,
        bgcolor: color.light_gray,
        icon: <Icon.search />,
        style: { padding: '20px', marginLeft: '4px' },
        size: 'large',
      };
      break;
  }
  const { icon, ...rest } = stuff;
  return (
    useFor && (
      <Tooltip placement="top" title={text}>
        <span style={{ padding: '2px' }}>
          <ButtonIconStyle shape="circle" size={stuff.size || 'middle'} {...rest}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ height: '100%', marginTop: '2px' }}>{icon}</div>
            </div>
          </ButtonIconStyle>
        </span>
      </Tooltip>
    )
  );
};
