import WrapperComponent from '../components/hoc/Wrapper-component';
import { Warranty } from './registration/register-line/Register';
import { IndexScene } from '../screens/index';
import { RegisterInfo } from './registration/registration-info/RegisterInfo';
import { HomeScene } from './home/HomeScene';
import { RegisterTelScene } from './registration/register-tel/RegisterTelScene';
import { ClaimScene } from './claims/ClaimScene';
import { CouponConfirmScene } from './coupons/confirm/CouponConfirmScene';
import { ProfileScene } from './profile/ProfileScene';
import { CouponUsed } from './coupon-used/CouponUsed';
import { TermWarranty } from './term/warranty/TermWarranty';

export default {
  Warranty: WrapperComponent('Warranty')(Warranty),
  IndexScene: WrapperComponent('IndexScene')(IndexScene),
  RegisterInfo: WrapperComponent('RegisterInfo')(RegisterInfo),
  HomeScene: WrapperComponent('HomeScene')(HomeScene),
  RegisterTelScene: WrapperComponent('RegisterTelScene')(RegisterTelScene),
  ClaimScene: WrapperComponent('ClaimScene')(ClaimScene),
  CouponConfirmScene: WrapperComponent('CouponConfirmScene')(CouponConfirmScene),
  ProfileScene: WrapperComponent('ProfileScene')(ProfileScene),
  CouponUsed: WrapperComponent('CouponUsed')(CouponUsed),
  TermWarranty: WrapperComponent('TermWarranty')(TermWarranty),
};
