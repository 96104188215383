import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Col, Row, Checkbox, Typography } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { RenderForm } from '../../../../components/forms';
import { ButtonTheme } from '../../../../components/buttons';
import { useModal } from '../../../../components/modal/hooks/useModal';
import { FORM_INPUT_CLAIM_INSERT, FORM_INPUT_CLAIM_ADDRESS, schemaClaimInsert } from '../../forms';
import { useFormState, useGetOptions } from '../../hooks';
import { ClaimExampleModal } from '../../views/example-model/ClaimExampleModal';

export const ClaimInsertForm = (props) => {
  const { warrantyData, handleSubmitSuccess } = props;
  const [isComfirm, setConfirm] = useState(false);
  const { open, onToggle } = useModal();
  const { claimInsertDefaultValues } = useFormState();
  const {
    optionsBreakdowns,
    optionsOldAddress,
    optionsProvinces,
    optionsDistricts,
    optionsSubDistricts,
    onFetchOptionsBreakdowns,
    onFetchOptionAddressZipCode,
    onSetOldAddresses,
    onProvinceSelected,
    onDistrictSelected,
    onOldAddressSelected,
    onResetAddressValues,
  } = useGetOptions();

  const { control, watch, setValue, getValues, handleSubmit } = useForm({
    claimInsertDefaultValues,
    resolver: yupResolver(schemaClaimInsert),
  });

  useEffect(() => {
    onFetchOptionsBreakdowns(warrantyData?.warranty?.breakdowns);
    onSetOldAddresses(warrantyData?.addresses);
    // set form ให้เลือก raio button หากลูกค้าเคยเพิ่มที่อยู่ไว้แล้ว จะเลือก default ไว้ที่ ที่อยู่เดิม
    setValue(
      'isCreateNewAddress',
      !warrantyData || !warrantyData.addresses || warrantyData.addresses.length == 0,
    );
  }, []);

  // handle address
  function handleZipCodeEnter(zipCode) {
    setValue('zipCode', zipCode);
    setValue('provinceId', null);
    setValue('districtId', null);
    setValue('subDistrictId', null);
    onFetchOptionAddressZipCode(zipCode);
  }
  const handleProvinceSelected = useCallback(
    (provinceId) => {
      setValue('provinceId', provinceId);
      setValue('districtId', null);
      setValue('subDistrictId', null);
      onProvinceSelected(provinceId, setValue);
    },
    [setValue, onProvinceSelected],
  );
  const handleDistrictSelected = useCallback(
    (districtId) => {
      setValue('districtId', districtId);
      setValue('subDistrictId', null);
      onDistrictSelected(districtId, setValue);
    },
    [setValue, onDistrictSelected],
  );

  const handleOldAddressSelected = useCallback(
    (oldAddressId) => {
      onOldAddressSelected(oldAddressId, setValue);
    },
    [setValue, onOldAddressSelected],
  );

  const handleNewAddressRaioSelected = useCallback(
    (value) => {
      setValue('isCreateNewAddress', value);
      onResetAddressValues(setValue);
    },
    [setValue, onResetAddressValues],
  );

  function onSubmitSuccess() {
    const values = getValues();
    handleSubmitSuccess(values);
  }

  // auto get zipCode when user input zipcode equal 5 digits
  const zipCode = watch('zipCode');
  const isCreateNewAddress = watch('isCreateNewAddress');
  useEffect(() => {
    if (zipCode?.length === 5 && isCreateNewAddress) {
      handleZipCodeEnter(zipCode);
    }
  }, [zipCode]);

  return (
    <div style={{ padding: '0 16px 3vh 16px', width: '100%' }}>
      <ClaimExampleModal open={open} onToggle={onToggle} />
      <RenderForm
        control={control}
        forms={FORM_INPUT_CLAIM_INSERT({ warranty: warrantyData?.warranty, optionsBreakdowns })}
        setValue={setValue}
        getValues={getValues}
      />
      <Row justify="end">
        <Typography.Link onClick={() => onToggle(true)} underline>
          ตัวอย่างภาพสินค้าเคลม
        </Typography.Link>
      </Row>

      <RenderForm
        control={control}
        forms={FORM_INPUT_CLAIM_ADDRESS({
          watch,
          optionsOldAddress,
          optionsProvinces,
          optionsDistricts,
          optionsSubDistricts,
          onZipCodeEnter: handleZipCodeEnter,
          onProvinceSelected: handleProvinceSelected,
          onDistrictSelected: handleDistrictSelected,
          onOldAddressSelected: handleOldAddressSelected,
          onNewAddressRaioSelected: handleNewAddressRaioSelected,
        })}
        setValue={setValue}
        getValues={getValues}
      />

      <Row>
        <Checkbox
          style={{ color: 'red' }}
          onChange={() => {
            setConfirm(!isComfirm);
          }}
        >{`ข้าพเจ้าขอรับรองว่าข้อมูลข้างต้นเป็นความจริงทุกประการ และข้อมูลดังกล่าวมีผลทางกฏหมาย`}</Checkbox>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTheme
            disabled={!isComfirm}
            useFor="CLAIM"
            title="แจ้งเคลมสินค้า"
            onClick={handleSubmit(onSubmitSuccess)}
          />
        </Col>
      </Row>
    </div>
  );
};

ClaimInsertForm.propTypes = {
  warrantyData: PropTypes.shape({
    warranty: PropTypes.object,
    addresses: PropTypes.array,
  }),

  handleSubmitSuccess: PropTypes.func.isRequired,
};

ClaimInsertForm.defaultProps = {
  warrantyData: {
    warranty: null,
    addresses: [],
  },
};
