import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ModalTheme } from '../../../../components/modal';
import { RenderForm } from '../../../../components/forms';
import { ContainerButton } from '../../../../styles/global-style';
import { ButtonTheme } from '../../../../components/buttons';
import { TextSmall, TextXSMall } from '../../../../components/text';
import { FORM_SUPERMEMBER } from '../forms';
import { promotionQuery } from '../../../../services/react-query/optionMasterQuery';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useInsertSuper } from '../hooks/useInsertSuper';
import { insertSuperMember } from '../../main';

export const SuperMember = ({ open, onToggle }) => {
  const { control, setValue, getValues, handleSubmit } = useForm();
  const { data: promotionList, isLoading: promotionLoading } = promotionQuery();

  const userProfile = useSelector((state) => state.info);

  const { mutate: onInsertSupermember } = useInsertSuper();

  useEffect(() => {
    if (userProfile) {
      setValue('telephone', userProfile?.telephone);
    }
  }, []);

  const onSubmit = (value) => {
    const formData = insertSuperMember(value);
    onInsertSupermember(formData);
    onToggle({ defaultValue: '' });
  };

  return (
    <Spin spinning={promotionLoading}>
      <ModalTheme title="ลงทะเบียน Super member" open={open} onToggle={onToggle}>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <TextSmall text="สำหรับการสมัครสมาชิกผ่าน Focus Store เท่านั้น" color="red" />
        </div>
        <div style={{ textAlign: 'center', width: '100%' }}>
          <TextXSMall text="หากไม่ได้ซื้อผ่าน Focus Store" color="blue" />
          <TextXSMall text="สนใจสมัคร lifetime คลิก" color="blue" />
        </div>

        <RenderForm
          control={control}
          setValue={setValue}
          forms={FORM_SUPERMEMBER({ promotionList })}
          getValues={getValues}
        />

        <ContainerButton right>
          <ButtonTheme useFor="OK" onClick={handleSubmit(onSubmit)} />
          <ButtonTheme useFor="CANCEL" onClick={onToggle} />
        </ContainerButton>
      </ModalTheme>
    </Spin>
  );
};
