import { GET, GET_POLICIES_PDPA } from '../..';
import { useQuery } from '@tanstack/react-query';
import { openAlert } from '../../../components/alert/hooks';

export const getPolicyPDPAQuery = () =>
  useQuery({
    queryKey: ['getPolicyPDPAQuery'],
    queryFn: async () => {
      try {
        const response = await GET(GET_POLICIES_PDPA);
        if (response.success) {
          return response.result?.item?.policyText;
        }
      } catch (error) {
        openAlert({ model: 'message', type: 'error', message: error.message });
      }
    },
  });
