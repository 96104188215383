export const useFormState = () => {
  const defaultValues = {
    title: '',
    couponCode: '',
    productName: '',
    promotionImageUrl: '',
    qrCode: '',
  };

  return {
    defaultValues,
  };
};
