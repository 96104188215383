import { useHistory } from 'react-router-dom';
import React from 'react';
import { Row, Col, Typography, Flex } from 'antd';
import { color } from '../../../../../resources/color';
import { ROUTES_PATH } from '../../../../../resources/routes-name';
import { Icon } from '../../../../../resources/icon';

const boxStyle = {
  borderRadius: 6,
  width: '50%',
  padding: 5,
  boxSizing: 'border-box',
  lineHeight: '1.5714285714285714',
  backgroundColor: '#ffffff',
  border: '1px solid #f0f0f0',
  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
};
const { Text } = Typography;

export const WarrantyAddCard = () => {
  const history = useHistory();

  const handleWarrantyClick = () => {
    const path = `${ROUTES_PATH.ROUTE_REGISTER.PATH}`;
    history.push(path);
  };

  return (
    <Row>
      <Col span={24}>
        <Flex style={{ width: '100%' }} justify={'center'} align={'center'}>
          <Flex
            vertical={true}
            style={boxStyle}
            justify={'center'}
            align={'center'}
            onClick={handleWarrantyClick}
          >
            <Icon.add style={{ width: '30px', height: '30px', color: color.primary }} />
            <Text strong>ลงทะเบียนสินค้าเพิ่ม</Text>
          </Flex>
        </Flex>
      </Col>
    </Row>
  );
};
