export const color = {
  theme: 'red',
  // linear-gradient(#e66465, #9198e5);
  edit: '#ff7b54',
  submit: '#7eca9c',
  clear: '#707070',
  remove: '#d35d6e',
  search: '#1890ff',
  red: '#FF0000',
  logout: '#737373',

  otp_confirm: '#4765f9',
  content_background: '#F8F8F8',
  yellow: '#F4D35E',
  orange: '#F99F47',
  gray: '#98989A',
  drak_gray: '#373737',
  light_gray: '#E5E5E5',
  blue: '#4765f9',
  navy_blue: '#3F51B5',
  bg_yellow: '#fef60029',
  bg_orange: '#f99f4729',
  bg_gray: '#98989a29',
  bg_blue: '#2f54eb29',
  bg_red: '#ff000029',
};
