import React from 'react';
import { Flex, Layout } from 'antd';
const { Footer } = Layout;
import { VERSION } from '../../../../resources';

const FooterLayout = () => {
  return (
    <Footer
      style={{
        textAlign: 'center',
        width: '100vw',
        height: '3vh',
        padding: '0px',
        position: 'fixed',
        bottom: 0,
      }}
    >
      <Flex style={{ width: '100%', height: '100%' }} justify={'center'} align={'center'}>
        ZettaSoft @2024 {VERSION}
      </Flex>
    </Footer>
  );
};

export default FooterLayout;
