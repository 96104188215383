import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Flex, Typography } from 'antd';
import { ModalCustomizeTheme } from '../../../../components/modal/index.jsx';
import { ImageTheme } from '../../../../components/images/index.jsx';
import { textStyle } from '../../styles';
import broken1 from '../../../../assets/images/broken01.jpg';
import broken2 from '../../../../assets/images/broken02.jpg';
import broken3 from '../../../../assets/images/broken03.jpg';
import broken4 from '../../../../assets/images/broken04.jpg';
import broken5 from '../../../../assets/images/broken05.jpg';
import broken6 from '../../../../assets/images/broken06.jpg';

export const ClaimExampleModal = (props) => {
  const { open, onToggle } = props;
  const imgsUrl = [broken1, broken2, broken3, broken4, broken5, broken6];
  return (
    <ModalCustomizeTheme title="ภาพตัวอย่างที่ถูกต้อง" open={open} onToggle={onToggle}>
      <Row style={textStyle.imgBox}>
        <Col span={24}>
          <Flex justify="center" align="center">
            <Row gutter={5}>
              {imgsUrl &&
                imgsUrl.map((item, index) => (
                  <Col span={8} key={index} style={{ marginTop: '5px' }}>
                    <ImageTheme src={item} />
                  </Col>
                ))}
            </Row>
          </Flex>
        </Col>
      </Row>
      <Row style={{ marginTop: '10px' }}>
        <Col span={24}>
          <Typography style={textStyle.content}>{`ขอสงานสิทธิ์ในการเคลมสินค้า`}</Typography>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Typography
            style={textStyle.content}
          >{`ในกรณีที่ฟิล์มกระจกได้รับความเสียหายจริงเท่านั้น`}</Typography>
        </Col>
      </Row>
    </ModalCustomizeTheme>
  );
};

ClaimExampleModal.propTypes = {
  open: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
};

ClaimExampleModal.defaultProps = {
  open: false,
  onToggle: () => null,
};
