import { useMutation } from '@tanstack/react-query';
import { POST, POST_GET_COUPON } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useGetCoupon = () => {
  return useMutation({
    mutationFn: async ({ couponId, couponCode }) => {
      const payload = {
        couponId,
        couponCode,
      };
      const response = await POST(POST_GET_COUPON(), payload);

      return response.result;
    },
    onError: (error) => {
      openAlert({
        model: 'message',
        type: 'error',
        message: error.message,
      });
    },
  });
};
