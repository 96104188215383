import React from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { ClaimContentCard } from './ClaimContentCard';

export const ClaimsView = (props) => {
  const { items = null } = props;

  return (
    <>
      {items && Array.isArray(items) && items.length > 0 ? (
        items?.map((item, index) => <ClaimContentCard key={item.id || index} item={item} />)
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </>
  );
};

ClaimsView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      productImageUrl: PropTypes.string,
      claimCode: PropTypes.string,
      warrantyCode: PropTypes.string,
      mobileModelName: PropTypes.string,
      productName: PropTypes.string,
      expireDate: PropTypes.string,
      fullName: PropTypes.string,
      telephone: PropTypes.string,
      claimStatusName: PropTypes.string,
      claimStatus: PropTypes.string,
      shippingName: PropTypes.string,
      trackingNumber: PropTypes.string,
    }),
  ),
};

ClaimsView.defaultProps = {
  items: [],
};
