import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { openLoading, closeLoading } from '../../../components/alert/hooks/useAlert';
import { openAlert } from '../../../components/alert/hooks';
import { GET, GET_OPTION_ADDRESSES_BY_ZIPCODE } from '../../../services';

export const useGetOptions = () => {
  const [optionsBreakdowns, setOptionsBreakdowns] = useState([]);
  const [address, setAddress] = useState([]);
  const [oldAddresses, setOldAddresses] = useState([]);
  const [optionsOldAddress, setOptionsOldAddress] = useState([]);
  const [optionsProvinces, setOptionsProvinces] = useState([]);
  const [optionsDistricts, setOptionsDistricts] = useState([]);
  const [optionsSubDistricts, setOptionsSubDistricts] = useState([]);

  const onFetchOptionsBreakdowns = async (breakdowns) => {
    const breakdownsOptions =
      breakdowns &&
      Array.isArray(breakdowns) &&
      breakdowns.map((breakdown) => {
        return {
          label: breakdown.breakdownNameTh,
          value: breakdown.breakdownId,
        };
      });
    setOptionsBreakdowns(breakdownsOptions);
  };

  const onSetOldAddresses = async (addresses) => {
    const oldAddressOptions =
      addresses &&
      Array.isArray(addresses) &&
      addresses.map((addr) => {
        return {
          label: addr.addressFullText,
          value: addr.customerAddressId,
        };
      });
    setOldAddresses(addresses);
    setOptionsOldAddress(oldAddressOptions);
  };

  const onOldAddressSelected = (oldAddressId, setValue) => {
    if (!oldAddresses || !oldAddressId) {
      setOptionsOldAddress([]);
      return;
    }

    const oldAddress = oldAddresses.find((addr) => addr.customerAddressId === oldAddressId);

    if (!oldAddress) {
      console.error('Old address not found');
      return;
    }
    // set option for selected old address
    setOptionsProvinces([
      {
        label: oldAddress.provinceName,
        value: oldAddress.provinceId,
      },
    ]);
    setOptionsDistricts([
      {
        label: oldAddress.districtName,
        value: oldAddress.districtId,
      },
    ]);
    setOptionsSubDistricts([
      {
        label: oldAddress.subDistrictName,
        value: oldAddress.subDistrictId,
      },
    ]);
    // set value for selected old address
    setValue('oldAddresses', oldAddressId);
    setValue('provinceId', oldAddress.provinceId);
    setValue('districtId', oldAddress.districtId);
    setValue('subDistrictId', oldAddress.subDistrictId);
    setValue('zipCode', oldAddress.zipcode);
    setValue('other', oldAddress.other);
  };

  const onFetchOptionAddressZipCode = async (zipCode) => {
    if (zipCode) {
      openLoading();
      addressZipCodeGet.mutate(zipCode);
    } else {
      setAddress(null);
      setOptionsProvinces(null);
      setOptionsDistricts(null);
      setOptionsSubDistricts(null);
    }
  };

  const addressZipCodeGet = useMutation({
    mutationFn: async (zipCode) => {
      const response = await GET(GET_OPTION_ADDRESSES_BY_ZIPCODE(zipCode));
      closeLoading();

      return response.result;
    },
    onSuccess: (data) => {
      setAddress(data);
      setAddressProvinces(data);
    },
    onError: (error) => {
      openAlert({ model: 'modal', type: 'error', message: error?.message });
    },
  });

  const setAddressProvinces = (data) => {
    setOptionsProvinces([]);
    setOptionsDistricts([]);
    setOptionsSubDistricts([]);
    if (!data?.province || !Array.isArray(data.province)) {
      return;
    }

    const provinces = data.province.map(({ provinceNameTh, provinceId }) => ({
      label: provinceNameTh,
      value: provinceId,
    }));

    setOptionsProvinces(provinces);
  };

  const onProvinceSelected = (provinceId, setValue) => {
    if (!address?.district || !provinceId) {
      setOptionsDistricts([]);
      return;
    }

    const districts = address.district
      .filter((district) => district.provinceId === provinceId)
      .map(({ districtNameTh, districtId }) => ({
        label: districtNameTh,
        value: districtId,
      }));

    setOptionsDistricts(districts);
    // set first select
    const firstDistrictValue = districts.length === 1 && districts[0]?.value;
    if (firstDistrictValue) {
      setValue('districtId', firstDistrictValue);
      onDistrictSelected(firstDistrictValue, setValue);
    } else {
      setValue('districtId', null);
    }
  };

  const onDistrictSelected = (districtId, setValue) => {
    if (!address?.subDistrict || !districtId) {
      setOptionsSubDistricts([]);
      return;
    }

    const subDistricts = address.subDistrict
      .filter((subDistrict) => subDistrict.districtId === districtId)
      .map(({ subDistrictNameTh, subDistrictId }) => ({
        label: subDistrictNameTh,
        value: subDistrictId,
      }));

    setOptionsSubDistricts(subDistricts);
    // set first select
    const firstSubDistrictValue = subDistricts.length === 1 && subDistricts[0]?.value;
    if (firstSubDistrictValue) {
      setValue('subDistrictId', firstSubDistrictValue);
    } else {
      setValue('subDistrictId', null);
    }
  };

  const onResetAddressValues = (setValue) => {
    setOptionsProvinces([]);
    setOptionsDistricts([]);
    setOptionsSubDistricts([]);
    setValue('provinceId', null);
    setValue('districtId', null);
    setValue('subDistrictId', null);
    setValue('zipCode', null);
    setValue('other', null);
    setValue('oldAddresses', null);
  };

  return {
    optionsBreakdowns,
    optionsOldAddress,
    optionsProvinces,
    optionsDistricts,
    optionsSubDistricts,
    onFetchOptionsBreakdowns,
    onFetchOptionAddressZipCode,
    onSetOldAddresses,
    onProvinceSelected,
    onDistrictSelected,
    onOldAddressSelected,
    onResetAddressValues,
  };
};
