import React from 'react';
import PropTypes from 'prop-types';
import { WarrantyContentCard } from './WarrantyContentCard';
import { WarrantyAddCard } from './WarrantyAddCard';

export const WarrantiesView = (props) => {
  const { items } = props;

  return (
    <>
      {items &&
        Array.isArray(items) &&
        items?.map((item, index) => <WarrantyContentCard key={item.id || index} item={item} />)}
      <WarrantyAddCard />
    </>
  );
};

WarrantiesView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      productImageUrl: PropTypes.string,
      warrantyId: PropTypes.number,
      warrantyCode: PropTypes.string,
      mobileModelName: PropTypes.string,
      productName: PropTypes.string,
      channelBuyName: PropTypes.string,
      fullName: PropTypes.string,
      telephone: PropTypes.string,
      createDate: PropTypes.string,
      expireDate: PropTypes.string,
      warrantyStatus: PropTypes.string,
    }),
  ),
};

WarrantiesView.defaultProps = {
  items: null,
};
