export const FORM_INPUT_POLICY = () => {
  return [
    {
      name: 'policyCheck',
      label: 'นโยบายและเงื่อนไขการให้บริการ',
      type: 'CHECKBOX',
      span: 12,
      properties: {
        options: [{ label: 'ยอมรับ', value: true }],
      },
      rules: {
        required: {
          value: true,
          message: 'กรุณายอมรับนโยบายและเงื่อนไขการให้บริการ',
        },
      },
    },
  ];
};

export const FORM_INPUT_REQUEST_OTP = () => {
  return [
    {
      name: 'telephone',
      label: 'เบอร์โทรศัพท์',
      type: 'INPUT',
      placeholder: 'กรอกเบอร์โทรศัพท์',
      span: 12,
      maxLength: 10,
      rules: {
        required: {
          value: true,
          message: 'กรุณากรอกเบอร์โทรศัพท์',
        },
      },
    },
  ];
};

export const FORM_INPUT_CONFIRM_OTP = ({ otpRef }) => {
  return [
    {
      name: 'otp',
      label: `รหัส OTP  [รหัสอ้างอิง : ${otpRef}]`,
      type: 'INPUT',
      placeholder: 'กรอก OTP',
      span: 12,
      maxLength: 6,
      rules: {
        required: {
          value: true,
          message: 'กรุณากรอก OTP',
        },
      },
    },
  ];
};
