import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row, Typography, Divider } from 'antd';
import Swal from 'sweetalert2';
import { useFormState } from '../../hooks';
import { ButtonTheme } from '../../components/buttons';
import { ImageTheme } from '../../../../../components/images';
import { color } from '../../../../../resources/color';
import { ROUTES_PATH } from '../../../../../resources/routes-name';

export const CouponMainForm = (props) => {
  const { data = null, handleSubmitSuccess = () => null } = props;
  const history = useHistory();
  const { defaultValues } = useFormState();
  const [coupon, setCoupon] = useState(defaultValues);

  useEffect(() => {
    setCoupon(data);
  }, [data]);

  function onSubmitSuccess() {
    Swal.fire({
      title: 'ยืนยันใช้สิทธิ์หรือไม่?',
      showCancelButton: true,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
    }).then((result) => {
      if (result.isConfirmed) {
        handleSubmitSuccess();
      }
    });
  }

  function onClose() {
    const path = `${ROUTES_PATH.ROUTE_HOME.PATH}?tab=coupon`;
    history.push(path);
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Row justify="center">
            <ImageTheme src={coupon && coupon?.promotionImageUrl} />
          </Row>
          <br></br>
          <div style={{ padding: '15px' }}>
            <Row>
              <Typography.Text style={{ fontSize: '16px' }}>
                {coupon && coupon?.title}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text style={{ fontSize: '16px', color: color.gray }}>
                {coupon && coupon?.productName}
              </Typography.Text>
            </Row>
            <Divider variant="dashed" style={{ borderColor: color.gray }} />
            <Row>
              <Col span={24}>
                <Typography.Text style={{ fontSize: '14px', color: color.red }}>
                  {`หมายเหตุ *หากกดยืนยันรับสิทธิ์ถือว่าท่านได้รับสินธิ์ส่วนลดทันที และสิทธิ์จะถูกตัดจากสิทธิ์ที่ท่านได้รับ`}
                </Typography.Text>
              </Col>
            </Row>
            <br></br>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <ButtonTheme
                  useFor="COUPON_USED"
                  title="ยืนยันใช้สิทธิ์"
                  onClick={onSubmitSuccess}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ textAlign: 'center' }}>
                <ButtonTheme useFor="COUPON_BACK" title="ยกเลิก" onClick={onClose} />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

CouponMainForm.propTypes = {
  data: PropTypes.shape({
    promotionImageUrl: PropTypes.string,
    title: PropTypes.string,
    productName: PropTypes.string,
  }),
  handleSubmitSuccess: PropTypes.func.isRequired,
};

CouponMainForm.defaultProps = {
  data: { promotionImageUrl: '', title: '', productName: '' },
  handleSubmitSuccess: () => null,
};
