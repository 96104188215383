import { POST, POST_CLAIM_WARRANTY_VERIT } from '../..';
import { useQuery } from '@tanstack/react-query';
import { openAlert } from '../../../components/alert/hooks';

export const getClaimWarrantyVerify = (warrantyId, warrantyCode) =>
  useQuery({
    queryKey: ['getClaimWarrantyVerify'],
    queryFn: async () => {
      try {
        const data = { warrantyId, warrantyCode };
        const response = await POST(POST_CLAIM_WARRANTY_VERIT, data);

        if (response.success) {
          return response?.result;
        }
      } catch (error) {
        openAlert({ model: 'notification', type: 'error', message: error.message });
      }
    },
    enabled: false,
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
