import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Row, Col, Typography, Flex } from 'antd';
import { ButtonTheme } from '../../../../../components/buttons';
import { ImageTheme } from '../../../../../components/images';
import { color } from '../../../../../resources/color';
import { ROUTES_PATH } from '../../../../../resources/routes-name';

const { Text } = Typography;
export const WarrantyContentCard = (props) => {
  const { item } = props;
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const productImageUrl = item && item.productImageUrl ? item.productImageUrl : '';
  const warrantyId = item && item.warrantyId ? item.warrantyId : 0;
  const warrantyCode = item && item.warrantyCode ? item.warrantyCode : '-';
  const mobileModelName = item && item.mobileModelName ? item.mobileModelName : '-';
  const productName = item && item.productName ? item.productName : '-';
  const channelBuyName = item && item.channelBuyName ? item.channelBuyName : '-';
  const fullName = item && item.fullName ? item.fullName : '-';
  const telephone = item && item.telephone ? item.telephone : '-';
  const createDate = item && item.createDate ? moment(item.createDate).format('DD/MM/YYYY') : '-';
  const expireDate = item && item.expireDate ? moment(item.expireDate).format('DD/MM/YYYY') : '-';
  const isExpired = item && item.warrantyStatus === 'EXPIRED' ? true : false;

  const handleClaimClick = () => {
    const path = `${ROUTES_PATH.ROUTE_CLAIM_REGISTER.PATH}?id=${warrantyId}&code=${warrantyCode}`;
    history.push(path);
  };

  return (
    <div
      style={{
        borderRadius: '0px 25px 25px 0px',
        padding: '5px',
        boxSizing: 'border-box',
        lineHeight: '1.5714285714285714',
        backgroundColor: '#ffffff',
        border: '1px solid #f0f0f0',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        width: '100%',
        marginBottom: '10px',
      }}
    >
      <Row gutter={10} style={{ paddingLeft: '10px' }}>
        <Col className="gutter-row" span={8}>
          <Row>
            <ImageTheme
              src={productImageUrl}
              style={{ width: '100%', height: '100%', objectFit: 'cover', marginTop: '20px' }}
            />
          </Row>
        </Col>
        <Col className="gutter-row" span={16}>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`รหัสรับประกัน:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ color: color.orange, fontSize: 12 }}>
                {warrantyCode}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`รุ่น:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ fontSize: 12 }}>
                {mobileModelName}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`ชื่อสินค้า:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ fontSize: 12 }}>
                {productName}
              </Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`ระยะเวลาประกัน:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text
                strong
                style={{ color: color.orange, fontSize: 12 }}
              >{`${createDate} - ${expireDate}`}</Text>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col className="gutter-row" span={10}>
              <Text style={{ fontSize: 12 }}>{`ช่องทางการซื้อ:`}</Text>
            </Col>
            <Col className="gutter-row" span={14}>
              <Text strong style={{ color: color.orange, fontSize: 12 }}>
                {channelBuyName}
              </Text>
            </Col>
          </Row>

          <Row gutter={10}>
            <Col className="gutter-row" span={24}>
              <Flex style={{ width: '100%' }} justify={'end'} align={'center'}>
                <Button type="text" onClick={() => setIsHovered(!isHovered)}>
                  <Text underline style={{ color: color.gray, fontSize: 12 }}>
                    {isHovered ? 'ซ่อน' : `เพิ่มเติม`}
                  </Text>
                </Button>
              </Flex>
            </Col>
          </Row>
          {isHovered && (
            <>
              <Row gutter={10}>
                <Col className="gutter-row" span={10}>
                  <Text style={{ fontSize: 12 }}>{`ชื่อ-นามสกุล:`}</Text>
                </Col>
                <Col className="gutter-row" span={14}>
                  <Text strong style={{ color: color.orange, fontSize: 12 }}>
                    {fullName}
                  </Text>
                </Col>
              </Row>
              <Row gutter={10}>
                <Col className="gutter-row" span={10}>
                  <Text style={{ fontSize: 12 }}>{`เบอร์โทรศัพท์:`}</Text>
                </Col>
                <Col className="gutter-row" span={14}>
                  <Text strong style={{ color: color.orange, fontSize: 12 }}>
                    {telephone}
                  </Text>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
      {isExpired && (
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Text
              strong
              style={{ color: color.red, fontSize: 12 }}
            >{`สินค้าหมดระยะเวลารับประกัน`}</Text>
          </Col>
        </Row>
      )}
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <ButtonTheme
            disabled={isExpired}
            useFor="CLAIM"
            title="เคลมสินค้า"
            onClick={handleClaimClick}
          />
        </Col>
      </Row>
    </div>
  );
};

WarrantyContentCard.propTypes = {
  item: PropTypes.shape({
    productImageUrl: PropTypes.string,
    warrantyId: PropTypes.number,
    warrantyCode: PropTypes.string,
    mobileModelName: PropTypes.string,
    productName: PropTypes.string,
    createDate: PropTypes.string,
    expireDate: PropTypes.string,
    channelBuyName: PropTypes.string,
    fullName: PropTypes.string,
    telephone: PropTypes.string,
    warrantyStatus: PropTypes.string,
  }),
};
WarrantyContentCard.defaultProps = {
  item: {
    productImageUrl: '',
    warrantyId: 0,
    warrantyCode: '-',
    mobileModelName: '-',
    productName: '-',
    channelBuyName: '-',
    fullName: '-',
    telephone: '-',
    createDate: '-',
    expireDate: '-',
    warrantyStatus: '',
  },
};
