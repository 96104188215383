import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StyledContainer } from '../../../../../components/container/styles';
import { Col, Row, Typography } from 'antd';
import { openAlert } from '../../../../../components/alert/hooks';
const { Text } = Typography;
export const TimerForm = (props) => {
  const { handleResend, initialTime } = props;
  const [timing, setTiming] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTiming((prevTime) => {
        // when timer is expired
        if (prevTime <= 0) {
          clearInterval(timer);
          handleResendClick();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleResendClick = () => {
    openAlert({
      model: 'modal',
      type: 'warning',
      message: 'รหัส OTP หมดอายุ \nกรุณาขอรหัส OTP ใหม่อีกครั้ง',
    });
    handleResend();
    setTiming(defaultTiming);
  };

  return (
    <StyledContainer>
      <Row>
        <Col span={24} style={{ textAlign: 'center' }}>
          <Text>{`รหัส OTP จะหมดอายุใน ${formatTime(timing)} นาที`} </Text>
        </Col>
      </Row>
    </StyledContainer>
  );
};

TimerForm.propTypes = {
  handleResend: PropTypes.func.isRequired,
  initialTime: PropTypes.number,
};

TimerForm.defaultProps = {
  initialTime: 300,
  handleResend: () => null,
};
