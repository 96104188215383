export const useFormState = () => {
  const claimInsertDefaultValues = {
    claimImage: null,
    warrantyId: 0,
    warrantyCode: '',
    detail1: '',
    breakdownId: 0,
    subDistrictId: 0,
    zipCode: '',
    other: '',
    isCreateNewAddress: true,
  };

  return {
    claimInsertDefaultValues,
  };
};
