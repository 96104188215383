import { GET, GET_ITEMS } from '../..';
import { useQuery } from '@tanstack/react-query';
import { openAlert } from '../../../components/alert/hooks';

export const getItemsQuery = (id) =>
  useQuery({
    queryKey: ['getItemsQuery'],
    queryFn: async () => {
      try {
        const response = await GET(GET_ITEMS(id));
        if (response.success) {
          return response.result;
        }
      } catch (error) {
        openAlert({ model: 'modal', type: 'error', message: error.message });
      }
    },
    enabled: false,
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
