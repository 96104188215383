import { color } from '../../../resources/color';
export const textStyle = {
  textTitle: {
    color: color.gray,
    fontSize: 18,
  },
  textContent: {
    fontSize: 18,
  },
};
