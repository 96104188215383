import { reducerBuilder } from '../../functions/redux';
import * as TYPE from './types';

// const initialState = {
//   profile: {
//     userId: 'testdev1',
//     displayName: 'Cat meow',
//     pictureUrl:
//       'https://images.pexels.com/photos/104827/cat-pet-animal-domestic-104827.jpeg?cs=srgb&dl=pexels-pixabay-104827.jpg&fm=jpg',
//   },
//   loading: false,
// };

const initialState = {
  profile: {
    userId: null,
    displayName: null,
    pictureUrl: null,
  },
  loading: false,
};
export const authenLineReducer = reducerBuilder(initialState, (builder) =>
  builder
    .addCase(TYPE.AUTHEN_LOGIN_LINE_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_LOGIN_LINE_SUCCESS, (state, { payload }) => {
      const { userProfile } = payload;

      state.loading = false;
      state.profile = userProfile;
    })
    .addCase(TYPE.AUTHEN_LOGIN_LINE_FAIL, (state, { message }) => {
      state.loading = true;
      state.error = message;
    })
    .addCase(TYPE.AUTHEN_LOGOUT_LINE_REQ, (state) => {
      state.loading = true;
    })
    .addCase(TYPE.AUTHEN_LOGOUT_LINE_SUCCESS, (state) => {
      state.loading = false;
      state.users = [];
    })
    .addCase(TYPE.AUTHEN_LOGOUT_LINE_FAIL, (state, { message }) => {
      state.loading = false;
      state.error = message;
    }),
);
