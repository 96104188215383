import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Empty } from 'antd';
import { useFormState } from '../../../hooks/useFormState';
import { CouponNavbarView } from './CouponNavbarView';
import { CouponContentCard } from './CouponContentCard';

export const CouponsView = (props) => {
  const { items } = props;
  const { tabCouponContents: contents } = useFormState({});
  const [couponItems, setCouponItems] = useState(null);

  const [activeKey, setActiveKey] = useState(contents[0].key);

  useEffect(() => {
    if (items && items.length > 0) {
      setCouponItems(
        items.filter((item) => item.couponType.toUpperCase().includes(activeKey.toUpperCase())),
      );
    }
  }, [items, activeKey]);

  const onChange = useCallback(
    (key) => {
      setActiveKey(key);
      if (items && items.length > 0) {
        setCouponItems(
          items.filter((item) => item.couponType.toUpperCase().includes(key.toUpperCase())),
        );
      } else {
        setCouponItems([]);
      }
    },
    [items],
  );

  return (
    <>
      <div style={{ width: '100%' }}>
        <CouponNavbarView activeKey={activeKey} onChange={onChange} tabContents={contents} />
        {couponItems && Array.isArray(couponItems) && couponItems.length > 0 ? (
          couponItems?.map((item, index) => (
            <CouponContentCard key={item.id || index} item={item} />
          ))
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </>
  );
};

CouponsView.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      couponType: PropTypes.string,
      promotionImageUrl: PropTypes.string,
      couponId: PropTypes.number,
      couponCode: PropTypes.string,
      title: PropTypes.string,
      subTitle: PropTypes.string,
      qty: PropTypes.number,
      used: PropTypes.number,
    }),
  ),
};

CouponsView.defaultProps = {
  items: [],
};
