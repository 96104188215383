export const useFormState = ({ tab, warrantyScene, claimScene, couponScene }) => {
  const activeTab = tab ? tab.toUpperCase() : '';
  const tabContents = [
    {
      key: 'WARRANTY',
      title: 'สิทธิประกัน',
      count: 0,
      children: warrantyScene,
    },
    {
      key: 'CLAIM',
      title: 'สินค้าเคลม',
      count: 0,
      children: claimScene,
    },
    {
      key: 'COUPON',
      title: 'คูปอง',
      count: 0,
      children: couponScene,
    },
  ];

  const tabMap = new Map(tabContents.map((item) => [item.key, item]));
  const tabActive = tabMap.get(activeTab) || tabContents[0];

  const tabCouponContents = [
    {
      key: 'SERVICE',
      title: 'บริการ',
    },
    {
      key: 'DISCOUNT',
      title: 'ส่วนลด',
    },
    {
      key: 'BIRTHDAY',
      title: 'วันเกิด',
    },
    {
      key: 'REDEEM',
      title: 'สมนาคุณ',
    },
  ];

  return {
    tabContents,
    tabActive,
    tabCouponContents,
  };
};
