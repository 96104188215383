import { useMutation } from '@tanstack/react-query';
import { openLoading, closeLoading } from '../../../components/alert/hooks/useAlert';
import { POST, POST_CLAIM_INSERT } from '../../../services';
import { openAlert } from '../../../components/alert/hooks';

export const useInsertClaim = (props) => {
  const { onSuccessCallBack } = props;

  const insertClaimPost = useMutation({
    mutationFn: async (data) => {
      const formData = new FormData();
      const {
        claimImage,
        warrantyId,
        warrantyCode,
        detail1,
        breakdownId,
        subDistrictId,
        zipCode,
        other,
        isCreateNewAddress,
      } = data;

      if (claimImage?.[0]?.originFileObj) {
        formData.append('claimImage', claimImage[0].originFileObj);
      }
      formData.append('warrantyId', warrantyId ?? 0);
      formData.append('warrantyCode', warrantyCode ?? '');
      formData.append('detail1', detail1 ?? '');
      formData.append('breakdownId', breakdownId ?? 0);
      formData.append('subDistrictId', subDistrictId ?? 0);
      formData.append('zipCode', zipCode ?? '');
      formData.append('other', other ?? '');
      formData.append('isCreateNewAddress', isCreateNewAddress);

      const response = await POST(POST_CLAIM_INSERT, formData);

      closeLoading();
      return response?.result?.item;
    },
    onSuccess: async (data) => {
      openAlert({ model: 'message', type: 'success', message: 'ลงทะเบียนเคลมเรียบร้อย' });
      await new Promise((resolve) => setTimeout(resolve, 500));
      onSuccessCallBack(data);
    },
    onError: (error) => {
      openAlert({ model: 'modal', type: 'error', message: error?.message });
    },
  });

  const claimPost = (data) => {
    openLoading();
    insertClaimPost.mutate(data);
  };

  return {
    claimPost,
  };
};
