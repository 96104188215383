import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding: 16px;
`;

export const HomePageStyledContainer = styled.div`
  background-color: tranparent;
  position: fixed;
  top: 40px;
  width: 100%;
  padding-left: 5px;
`;
