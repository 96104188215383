import { create } from 'apisauce';
import { getQueryString } from '../functions';
import { getCookies } from '../store/useCookies';
import { env } from '../utils/env/config';
export const ip = 'http://192.168.1.169:2000';

export const MODE_API = {
  DEVELOPMENT: env.url_dev,
  UAT: env.url_uat,
  PRODUCTION: env.url_prd,
};
export const CONNECT_API = MODE_API[`${env.node_env}`.toUpperCase()]; // MODE_API[`${env.node_env}`.toUpperCase()];
const api = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // 'ngrok-skip-browser-warning': '69420',
  },
  timeout: 60000, // 60 seconds
});

const apiupload = create({
  baseURL: CONNECT_API,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const POST = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */
export const PUT = (path, obj, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .put(path, obj, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const UPLOAD = (path, formdata, token = getCookies('accessToken')) =>
  new Promise((resolve, reject) => {
    apiupload
      .post(path, formdata, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/* ################################################## URL ################################################## */
export const LOGIN = `/authen/login`;
export const LOGIN_GOOGLE = `/authen/login-google`;
export const REGISTER = `/authen/register`;
export const GET_PROFILE = `/authen/get_profile`;
export const GET_ALL_PRODUCT = `/product/get-all`;
export const GET_PRODUCT_SEARCH = (obj) => `/product/search?${getQueryString(obj)}`;

export const GET_ALL_POST = `/posts`;
export const INSERT_POST = `/posts`;
export const UPDATE_POST = (id) => `/posts/${id}`;
export const GET_POST_BY_ID = (id) => `/posts/${id}`;
export const GET_CUSTOMER_LINE = (userId) =>
  `/warranty/abm/api/v2/line/customers/lineuid/${userId}`;
export const GET_VERIFY_NUMBER = (warrantyNumberCode) =>
  `/warranty/abm/api/v2/line/serial-number/verify/${warrantyNumberCode}`;
export const GET_CHANNEL_BUY = () => `/warranty/abm/api/v2/line/options/channel-buys`;
export const GET_PROMOTION = () => `/warranty/abm/api/v2/line/promotions`;
export const POST_SUPERMEMBER = () => `/warranty/abm/api/v2/line/member-orders/insert`;
export const POST_WARRANTY = () => `/warranty/abm/api/v2/line/warranties/add`;
export const GET_POLICIES_PDPA = `/warranty/abm/api/v2/line/options/policies/pdpa`;
export const POST_REQUEST_OTP = `/warranty/abm/api/v2/line/otp/call`;
export const POST_CONFIRM_OTP = `/warranty/abm/api/v2/line/otp/verify`;
export const GET_ITEMS = (id) => `/warranty/abm/api/v2/line/items/${id}`;
export const GET_OPTION_ADDRESSES_BY_ZIPCODE = (id) =>
  `/warranty/abm/api/v2/line/options/addresses/zipcode/${id}`;
export const POST_CLAIM_WARRANTY_VERIT = `/warranty/abm/api/v2/line/claims/warranty/verify`;
export const POST_CLAIM_INSERT = `/warranty/abm/api/v2/line/claims/insert`;
export const POST_COUPON_VERIFY = `/warranty/abm/api/v2/line/coupons/verify`;
export const PUT_COUPON_USED_UPDATE = `/warranty/abm/api/v2/line/coupons/used`;
export const POST_GET_COUPON = () => `/warranty/abm/api/v2/line/coupons/verify`;
