import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setTitle } from '../../../redux/title/action';
import { RenderForm } from '../../../components/forms';
import { useForm } from 'react-hook-form';
import { FOMR_INPUT_REGISTER, FORM_SUPER_MEMBER_STORE } from './forms';
import { StyledContainer } from '../register-line/styles';
import { ContainerButton } from '../../../styles/global-style';
import { ButtonTheme } from '../../../components/buttons';
import { Checkbox, Spin, Typography, Row } from 'antd';
import { channelBuyQuery } from '../../../services/react-query/optionMasterQuery';
import { useProductOptions } from './func/useProductOptions';
import { useModal } from '../../../components/modal/hooks/useModal';
import { SuperMember } from './modals/SuperMember';
import { useInsertWarranty } from './hooks/useInsertWarranty';
import { handleSubmitForm } from './func/submitHandler';
import { ROUTES_PATH } from '../../../resources/routes-name';

const termsStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
  flexDirection: 'column',
};

export const RegisterInfo = () => {
  const { control, setValue, watch, getValues, handleSubmit } = useForm();
  const { open: openSuper, onToggle: onToggleSuper } = useModal();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isAccepted, setIsAccepted] = useState(false);
  const [, setIsSubmitting] = useState(false);
  const { warranty } = useSelector((state) => state.warranty) || {};
  const userProfile = useSelector((state) => state.info);

  const mobileBrandId = watch('mobileBrandId');
  const mobileModelId = watch('mobileModelId');

  const { data: channelBuyList, isLoading: channelBuyLoading } = channelBuyQuery();
  const { mutate: mutateUpload } = useInsertWarranty();

  const { productOptions, brandOptions, modelOptions } = useProductOptions(
    mobileBrandId,
    mobileModelId,
    setValue,
  );

  useEffect(() => {
    dispatch(setTitle('ลงทะเบียนรับประกันสินค้า'));
  }, [dispatch]);

  useEffect(() => {
    if (warranty) {
      setValue('warrantyNumberCode', warranty?.warrantyNumberCode);
    }

    if (brandOptions.length === 1) {
      setValue('mobileBrandId', brandOptions[0]?.value);
    }

    if (modelOptions.length === 1) {
      setValue('mobileModelId', modelOptions[0]?.value);
    }

    if (productOptions.length === 1) {
      setValue('mapProductId', productOptions[0]?.value);
    }
  }, [warranty, setValue, userProfile, productOptions, brandOptions, modelOptions]);

  useEffect(() => {
    if (warranty) {
      setValue('warrantyNumberCode', warranty?.warrantyNumberCode);
    }
    if (userProfile) {
      setValue('fullName', userProfile?.fullName);
      setValue('telephone', userProfile?.telephone);
    }
  }, [warranty, userProfile]);

  const handlePolicyClick = () => {
    const path = ROUTES_PATH.ROUTE_TERM_WARRANTY.PATH;
    history.push(path);
  };

  const handleCheckboxChange = (e) => setIsAccepted(e.target.checked);

  const onSubmit = (values) => handleSubmitForm(values, setIsSubmitting, mutateUpload);

  return (
    <Spin spinning={channelBuyLoading}>
      <StyledContainer style={{ paddingBottom: '10px' }}>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FOMR_INPUT_REGISTER({
            channelBuyList,
            productOptions,
            brandOptions,
            modelOptions,
          })}
        />
        <ContainerButton center>
          <ButtonTheme useFor="SUPERMEMBER" onClick={onToggleSuper} />
          {openSuper && <SuperMember open={openSuper} onToggle={onToggleSuper} />}
        </ContainerButton>
        <RenderForm
          control={control}
          setValue={setValue}
          getValues={getValues}
          forms={FORM_SUPER_MEMBER_STORE()}
        />
        <div style={termsStyle}>
          <Checkbox checked={isAccepted} onChange={handleCheckboxChange}>
            ฉันได้ยอมรับ
          </Checkbox>
          <ContainerButton center style={{ marginBottom: '13px' }}>
            <ButtonTheme
              useFor="REGISTER_WARRANTY"
              disabled={!isAccepted}
              onClick={handleSubmit(onSubmit)}
            />
          </ContainerButton>
          <Row justify="end">
            <Typography.Link onClick={() => handlePolicyClick(true)} underline>
              ข้อตกลงการรับประกันสินค้า
            </Typography.Link>
          </Row>
        </div>
      </StyledContainer>
    </Spin>
  );
};
