import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography, Divider, Flex } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ButtonTheme } from '../../components/buttons';
import { color } from '../../../../../resources/color';
import { openAlert } from '../../../../../components/alert/hooks';
import { useFormState } from '../../hooks';

export const CouponWebForm = (props) => {
  const { data } = props;
  const { defaultValues } = useFormState();
  const [coupon, setCoupon] = useState(defaultValues);

  useEffect(() => {
    setCoupon(data);
  }, []);

  function onSubmitSuccess() {
    openAlert({ model: 'message', type: 'success', message: 'Copied!!' });
  }

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        background: color.orange,
        padding: '20px 15px 15px 20px',
      }}
    >
      <div
        style={{
          backgroundColor: color.content_background,
          paddingTop: '10px',
          textAlign: 'center',
          borderRadius: '12px',
        }}
      >
        <Flex vertical wrap justify="center" gap="small" style={{ padding: '10px' }}>
          <Typography.Text style={{ fontSize: '20px', color: color.gray }}>
            {coupon && coupon?.title}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '14px', color: color.gray }}>
            {coupon && coupon?.productName}
          </Typography.Text>
          <Typography.Text style={{ fontSize: '16px', color: color.gray }}>
            {`เฉพาะสินค้า ABLEMEN เท่านั้น`}
          </Typography.Text>
        </Flex>
        <Divider variant="dashed" style={{ borderColor: color.orange }} />
        <Row justify="center">
          <Typography.Text strong style={{ fontSize: '22px' }}>
            {coupon && coupon?.couponCode}
          </Typography.Text>
        </Row>
        <br></br>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <CopyToClipboard text={(coupon && coupon?.couponCode) || ''}>
              <ButtonTheme useFor="COUPON_USED" title="คัดลอก" onClick={onSubmitSuccess} />
            </CopyToClipboard>
          </Col>
        </Row>
        <br></br>
        <div
          style={{
            backgroundColor: color.light_gray,
            padding: '20px 10px 15px 10px',
            borderRadius: '0 0 12px 12px',
          }}
        >
          <Row align="center">
            <Typography.Text style={{ fontSize: '14px', color: color.red }}>
              {`กรุณาคัดลอกรหัสส่วนลดเพื่อนำไปเป็นส่วนลด`}
            </Typography.Text>
          </Row>
          <Row align="center">
            <Typography.Text style={{ fontSize: '14px', color: color.red }}>
              {`กรณีซื้อสินค้าผ่านเว็บไซต์ของ ABLEMEN`}
            </Typography.Text>
          </Row>
        </div>
      </div>
    </div>
  );
};

CouponWebForm.propTypes = {
  data: PropTypes.shape({
    couponCode: PropTypes.string,
    title: PropTypes.string,
    productName: PropTypes.string,
  }),
  handleSubmitSuccess: PropTypes.func.isRequired,
};

CouponWebForm.defaultProps = {
  data: { couponCode: '', title: '', productName: '' },
  handleSubmitSuccess: () => null,
};
