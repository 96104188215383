import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography, Row, Col, Flex, Divider, Space, QRCode } from 'antd';
import { color } from '../../resources/color';
import { textStyle } from './styles';
import { setQrCode, getCustomerLevel } from './hook';
import { ImageTheme } from '../../components/images';
import { ButtonTheme } from './components/buttons';
import { ROUTES_PATH } from '../../resources/routes-name';
import logo from '../../assets/icons/logo-abm.png';

export const ProfileScene = () => {
  const history = useHistory();
  const profile = useSelector((state) => state.info) || null;

  const qrCode = setQrCode(profile);
  const customerCode = profile ? profile.customerCode : '-';
  const customerLevel = profile ? getCustomerLevel(profile.customerLevel) : '-';
  const fullName = profile ? profile.fullName : '-';
  const telephone = profile ? profile.telephone : '-';
  const birthday = profile ? profile.birthday : '-';

  function onClose() {
    const path = `${ROUTES_PATH.ROUTE_HOME.PATH}`;
    history.push(path);
  }

  return (
    <div style={{ width: '100vw', height: '100%', overflowY: 'auto' }}>
      <Row>
        <Col span={24}>
          <Space direction="vertical" align="center" style={{ width: '100%', marginTop: 20 }}>
            <ImageTheme src={logo} style={{ width: 200 }} />
            <QRCode value={qrCode || '-'} bordered={false} size={200} />
            <Typography.Text strong style={{ fontSize: 20 }}>
              {customerCode}
            </Typography.Text>
          </Space>
        </Col>
      </Row>
      <Divider style={{ borderColor: color.light_gray }} />
      <Space direction="vertical" size={16} style={{ width: '100%', padding: '0 40px' }}>
        <Row>
          <Col span={10}>
            <Typography.Text style={textStyle.textTitle}>{`กลุ่มลูกค้า`}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={textStyle.textContent}>{customerLevel}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Typography.Text style={textStyle.textTitle}>{`ชื่อ-นามสกุล`}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={textStyle.textContent}>{fullName}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Typography.Text style={textStyle.textTitle}>{`เบอร์โทรศัพท์`}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={textStyle.textContent}>{telephone}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col span={10}>
            <Typography.Text style={textStyle.textTitle}>{`วันเกิด`}</Typography.Text>
          </Col>
          <Col span={14}>
            <Typography.Text style={textStyle.textContent}>{birthday}</Typography.Text>
          </Col>
        </Row>
        <Flex justify="center" style={{ width: '100%', marginTop: '15px' }}>
          <ButtonTheme useFor="BACK" title="กลับหน้าหลัก" onClick={onClose} />
        </Flex>
      </Space>
    </div>
  );
};
