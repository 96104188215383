import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ContentStyle, ContentStyleFullScreen } from '../../styles';
import NotfoundScene from '../../../../screens/not-found';
import { ROUTES_PATH, FULLSCEEN_ROUTES } from '../../../../resources/routes-name';

export const ContentLayout = ({ getTheme, sidebarMode, isFullScreenRoute }) => {
  return (
    <div>
      <Switch>
        <div>
          <ContentStyle
            style={{
              ...getTheme.sidebar[sidebarMode],
              overflowY: 'auto',
              display: isFullScreenRoute ? 'none' : 'flex',
            }}
          >
            {Object.values(ROUTES_PATH)
              .filter((route) => !FULLSCEEN_ROUTES.includes(route.KEY))
              .map((route) => (
                <Route exact key={route.KEY} path={route.PATH} component={route.COMPONENT} />
              ))}
          </ContentStyle>
          <ContentStyleFullScreen>
            {Object.values(ROUTES_PATH)
              .filter((route) => FULLSCEEN_ROUTES.includes(route.KEY))
              .map((route) => (
                <Route exact key={route.KEY} path={route.PATH} component={route.COMPONENT} />
              ))}
          </ContentStyleFullScreen>
        </div>

        <Route path="*" key="NOT_FOUND" component={NotfoundScene} />
      </Switch>
    </div>
  );
};

export default ContentLayout;
