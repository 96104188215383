import liff from '@line/liff';
import { call, put } from 'redux-saga/effects';
import { loginLineFail, loginLineSuccess } from './action';
import { setCookies } from '../../store/useCookies';
import { env } from '../../utils/env/config';
import { closeLoading, openAlert, openLoading } from '../../components/alert/hooks/useAlert';

export function* loginLineSaga() {
  try {
    openLoading();
    let userProfile = null;
    yield call(async () => {
      try {
        await liff.init({
          liffId: env.line_liff_id,
        });

        if (!liff.isLoggedIn()) {
          await liff.login();
        }

        userProfile = await liff.getProfile();
      } catch (error) {
        throw error;
      }
    });

    yield call(setCookies, 'userId', userProfile.userId);
    yield call(setCookies, 'displayName', userProfile.displayName);
    yield call(setCookies, 'pictureUrl', userProfile.pictureUrl);

    yield put(loginLineSuccess({ userProfile }));
  } catch (error) {
    console.log(error);
    openAlert({ type: 'error', message: error.message });
    yield put(loginLineFail(`LIFF initialization failed. : ${error}`));
  } finally {
    closeLoading();
  }
}
