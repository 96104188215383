export const setQrCode = (data) => {
  if (data) {
    const qrCode = {
      birthday: data.birthday,
      level: data.customerLevel,
      name: data.fullName,
      tel: data.telephone,
    };
    const text = JSON.stringify(qrCode);
    return text;
  }
  return '';
};

export const getCustomerLevel = (level) => {
  if (level && level.toUpperCase() === 'MEMBER') return 'ซุปเปอร์ เมมเบอร์';
  else return 'ทั่วไป';
};
