import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { HeaderBarStyle } from '../../styles';
import { TextLarge } from '../../../text';
import { ROUTES_PATH } from '../../../../resources/routes-name';

const HeaderLayout = () => {
  const history = useHistory();
  const title = useSelector((state) => state.title.title);
  const profile = useSelector((state) => state.line.profile);

  function handleProfileClick() {
    const path = ROUTES_PATH.ROUTE_PROFILE.PATH;
    history.push(path);
  }

  return (
    <HeaderBarStyle>
      <Row align="middle" justify="center">
        <Col span={4} />
        <Col span={16}>
          <TextLarge text={title} style={{ color: 'white' }} />
        </Col>
        <Col span={4}>
          {profile?.pictureUrl && (
            <img
              src={profile?.pictureUrl}
              alt="Profile"
              width="50"
              height="50"
              style={{ borderRadius: '50%', cursor: 'pointer' }}
              onClick={handleProfileClick}
            />
          )}
        </Col>
      </Row>
    </HeaderBarStyle>
  );
};

export default HeaderLayout;
